.sliding-text-container {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}

.sliding-text {
  display: inline-block;
  white-space: nowrap;
  animation: slide 10s linear infinite;

  -webkit-text-stroke: 1px #67fb94;
  color: transparent;

  font-family: "Inter";
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  text-transform: uppercase;
  opacity: 0.3;
}

.sliding-text.paused {
  animation-play-state: paused;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media (max-width: 900px) {
  .sliding-text {
    font-size: 80px;
  }
}
@media (max-width: 600px) {
  .sliding-text {
    font-size: 60px;
  }
}
@media (max-width: 400px) {
  .sliding-text {
    font-size: 40px;
  }
}
