.divider-container {
  position: relative;
  width: 100%;
}
.divider-line {
  height: 1px;
  opacity: 0.1;
  background: #fff;
  margin: 24px;
}
.quote {
  position: absolute;
  top: 12px;
  left: 80px;
  width: 26px;
  height: 26px;
  color: #161d27;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}
