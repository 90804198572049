.category-container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;

  max-width: 400px;
  height: 323px;
  padding: 40px 30px;
  border-radius: 8px;

  color: #fff;
  background-color: #161d27;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.category-highlighter {
  position: absolute;
  left: 0;
  top: 42px;

  width: 2px;
  height: 38px;
  background: #66fa93;
}
.blog-card-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  align-self: flex-start;
}
.blog-categories {
  width: 100%;
}
.blog-categories ul {
  list-style: none;
  padding: 0;
}
.blog-categories ul li {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}
.blog-categories ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  color: var(--Grey, #c0c0c2);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.blog-categories ul li a:hover {
  color: #66fa93;
}

.blog-categories ul li:hover .count {
  color: #66fa93;
}

.blog-categories ul li.selected a {
  color: #66fa93;
}
.blog-categories ul li.selected .count {
  color: #66fa93;
}

.blog-categories svg {
  width: 20px;
  height: 20px;
  stroke: var(--Grey, #c0c0c2);
}
.blog-categories ul li:hover svg {
  stroke: #66fa93;
}
.blog-categories ul li.selected svg {
  stroke: #66fa93;
}
