.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  background-color: #66fa9224;
  box-shadow: drop-shadow(0px 6px 50px rgba(37, 37, 37, 0.05));
  border-radius: 50%;
}
.button-container:hover {
  /* background-color: #66fa9224;
  cursor: pointer; */
}
.right-button {
  transform: rotate(180deg);
  fill: #000;
}
.button-container svg {
  fill: #fff;
}
.button-container:hover svg {
  fill: #000;
}

.button-container:hover {
  background-color: #66fa93;
}
