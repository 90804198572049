footer {
  width: 100%;
  height: 570px;
  margin-top: 40px;
}
.main-footer {
  width: 100%;
  height: 100%;
  background-image: url("https://res.cloudinary.com/dhbe6wtod/image/upload/v1720042313/werkix/home/werk-footer-bg_fktrj1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
}
.footer-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #0a1019e7;
  gap: 40px;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 80%;
  padding-top: 60px;
  margin: 0 auto;
}
.top-footer {
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px; */
  display: grid;
  grid-template-columns: 1.4fr 1fr 1fr 1fr;
}

.footer-column {
  flex: 1;
}
.footer-column a {
  color: #c0c0c2;
  text-decoration: none;
}

.footer-column .footer-about {
  color: var(--Grey, #c0c0c2);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 0 10px;

  width: 80%;
}

.footer-column-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  color: #c0c0c2;
}
.footer-column-items p {
  padding: 0;
  margin: 0;
}
.footer-column-items .location {
  color: #fff;
}
.footer-column-items a:hover {
  cursor: pointer;
  color: #67fb94;
}
.footer-heading {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 26px;
}

.footer-logo {
  width: 200px;
  /* height: 50px; */
}

.footer-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social-links {
  display: flex;
  gap: 10px;
  align-items: center;
}
.social-links svg {
  padding-left: 8px;
  fill: #67fb94;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

/* ------------------------------      MEDIA QUERRIES    ------------------------------ */
@media (max-width: 1080px) {
  .footer-about {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  .footer-wrapper {
    width: 90%;
  }
}
@media (max-width: 900px) {
  .footer-about {
    font-size: 16px;
    line-height: 4px;
  }
}
@media (max-width: 800px) {
  footer {
    height: 470px;
  }
  .footer-column:nth-child(2) {
    display: none;
  }

  .top-footer {
    grid-template-columns: 2fr 1fr 1fr;
  }
  .main-footer {
    height: fit-content;
  }
}
@media (max-width: 650px) {
  footer {
    height: 370px;
  }
  .footer-logo {
    width: 160px;
  }

  .top-footer {
    padding: 20px;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 12px;
  }
  .footer-wrapper {
    width: 100%;
  }
  .footer-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 34px;
  }
  .footer-about {
    font-size: 14px !important;
    line-height: 20px !important ;
  }
}
@media (max-width: 450px) {
  footer {
    height: fit-content;
  }
  .footer-heading {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 16px;
  }
  .footer-column a {
    font-size: 14px;
  }

  .footer-logo {
    width: 120px;
  }
}
