.project-card {
  position: relative;
  width: 100%;
  max-width: 410px;
  height: 314px;
  border-radius: 24px;
  opacity: 0.6;
  z-index: 0;
}

.project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  z-index: 1;
}

.project-overlay {
  position: absolute;
  width: 80%;
  height: 58px;
  bottom: -14px;
  left: 30px;
  color: #fff;
  border-radius: 10px;
  background: #0c0f14;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  padding: 10px;
}

.project-overlay:hover {
  background: #7ac14b;
}

@media (max-width: 1080px) {
  .project-overlay {
    height: 40px;
    line-height: 24px;

    left: 20px;
    bottom: -8px;
  }
}
@media (max-width: 968px) {
  .project-card {
    height: 280px;
  }
  .project-overlay {
    height: 50px;
    line-height: normal;
    font-size: 14px;

    left: 12px;
    bottom: -8px;
  }
}

@media (max-width: 500px) {
  .project-overlay {
    left: 22px;
    bottom: -8px;
  }
}
