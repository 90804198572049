.tags-container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  max-width: 400px;
  height: 186px;
  padding: 20px;
  border-radius: 8px;

  background-color: #161d27;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.tags-blog-highlighter {
  position: absolute;
  left: 0;
  top: 42px;

  width: 2px;
  height: 38px;
  background: #66fa93;
}

.tag-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;

  padding-left: 30px;
  align-self: flex-start;
}
.tag-container {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 10px;
}

.tag {
  width: 108px;
  height: 35px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #c0c0c260;

  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  border: 1px solid #ffffff16;
  cursor: pointer;
}

.tag:hover {
  border: 1px solid #66fa93;
}

.tag.selected {
  border: 1px solid #66fa93;
  color: #66fa93;
}
