.banner-container {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  /* margin-top: 2em; */
  transform: translateY(2em);

  background: transparent;
  position: absolute;
  top: 10%;
  z-index: 2;
}
.nav-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  list-style: none;
}

.nav-link {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  line-height: 25px;
  text-transform: capitalize;
}

.nav-list-primary .nav-link {
  color: #c0c0c2;
  position: relative;
  display: inline-block;
}
.nav-list-primary .nav-link:hover {
  color: #66fa93;
}
.nav-list-primary .nav-link.active {
  color: #66fa93;
}
.nav-list-primary .nav-link.active::before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #66fa93;
  bottom: 0;
  transition: width 0.3s ease-in-out;
}

.nav-list-primary .nav-link.active::before {
  width: 20%;
}

.nav-link-button {
  width: 201px;
  height: 60px;
  background: #66fa93;
  color: #161d27;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-link-button:hover {
  border: 1px solid #66fa93;
  background: transparent;
  cursor: pointer;
}
.nav-link-button a {
  color: black;
}

.nav-link-button:hover a {
  color: #fff;
}

.banner-container .logo {
  width: 200px;
}

.banner-container .logo img {
  width: 100%;
  height: 100%;
}

/*--------------  MEDIA QUERRIES ------------------ */
@media (min-width: 1255px) {
  .banner-container {
    max-width: 1600px;
  }
  .banner-container .nav {
  }
}
@media (max-width: 1255px) {
  .nav-list {
    justify-content: space-between;
    gap: 10px;
  }
  .nav-link {
    font-size: 16px;
    line-height: 20px;
  }
  .nav-link-button {
    width: 160px;
    height: 50px;
  }
}
@media (max-width: 1098) {
  .nav-link-button {
    width: 100px;
    height: 35px;
  }
}
@media (max-width: 1080px) {
  .banner-container {
    width: 90%;
  }
  .nav-list {
  }
  .nav-link {
    font-size: 14px;
    line-height: normal;
  }
  .nav-link-button {
    width: 140px;
    height: 40px;
  }
}
@media (max-width: 900px) {
  .banner-container {
    width: 94%;
  }
  .nav-link-button {
    width: 110px;
    height: 34px;
  }
  .nav-link {
  }
}
@media (max-width: 820px) {
  .nav {
    display: none;
  }
}
