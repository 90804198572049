.client-testimonial-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  display: flex;
  align-items: center;

  background: #0c0f14;
}

.client-section-header {
  text-align: start;
  padding: 40px 0;
}

.client-testimonial-wrap {
  width: 98%;
  margin: 0 auto;
}
.testimonials-wrapper {
  display: block;
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  gap: 20px !important;
}

@media (max-width: 550px) {
  .client-testimonial-container .home-heading {
    color: rgba(255, 255, 255, 0.4);
    font-size: 24px;
  }
}
@media (max-width: 375px) {
  .client-testimonial-container .client-testimo-card {
    width: fit-content;
    height: fit-content;
    margin: 4px auto;
  }

  .client-testimo-card .home-heading {
    font-size: 16px;
    font-weight: 400;
  }
  .client-testimo-card p {
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: normal;
  }
}
