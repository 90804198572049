.why-us-home {
  position: relative;

  height: fit-content;
  /* height: 1200px; */
  width: 100%;
  background: #161d27;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  overflow-x: hidden;
}

.why-us-wrap {
  display: flex;
  justify-content: center;
  gap: 30px;

  width: 100%;
  height: 600px;
  padding: 20px 0;
  color: #fff;
}
.why-wrap-secondary {
  display: flex;
  justify-content: center;
  gap: 30px;

  width: 100%;
  height: 400px;
  padding: 100px 0;
  color: #fff;
}
.why-us-home-bg {
  width: 100%;
  height: 600px;
}
.why-us-home-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 0px 260px 0px 400px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.why-us-wrapper-left {
  display: flex;
  flex: 1.6;
  flex-direction: column;
  gap: 20px;
  height: 600px;
  width: 100%;
  border-radius: 0px 260px 0px 1000px;
}
.why-us-wrapper-left-grid {
  width: 100%;
  height: 600px;

  display: flex;
  flex: 1;
  gap: 20px;

  padding: 0 20px;
}

.why-us-grid {
  display: flex;
  width: 100%;
  height: 350px;
  gap: 20px;
}

.why-us-grid img {
  width: 60%;
  height: 100%;
  object-fit: cover;

  border: 2px solid var(--Brand-green, #7ac14b);
}
.why-us-grid img:nth-child(1) {
  border-radius: 160px 24px 40px 24px;
}
.why-us-grid img:nth-child(2) {
  border-radius: 24px 160px 24px 24px;
  align-self: flex-end;
  margin-bottom: -44px;
  width: 50%;
}
.why-us-grid img:nth-child(3) {
  display: none;
}

.why-us-wrapper-right {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;

  flex: 1;

  height: 100%;
  width: 100%;

  padding: 10px 60px;
}

.why-text {
  color: #c0c0c2;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  position: relative;

  padding: 10px 90px 10px 0;
}
.why-text-secondary {
  color: #c0c0c2;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  position: relative;

  padding: 10px 110px 10px 0;
}

.read-more-btn {
  width: 100px;
  display: inline-block;
  padding: 5px 10px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.read-more-btn:hover {
  background-color: #66fa93;
  opacity: 0.8;
}
.why-us-counter {
  width: 100%;
}
.why-us-details {
  display: flex;
  justify-content: space-evenly;
}

.counter-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #161d27;
  margin: 4em auto;
}

.counter-detail:after {
  content: "";
  display: block;
  width: 100px;
  height: 1px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  bottom: 80px;
  right: -140px;
  transform: rotate(90deg);
  opacity: 0.2;
}

.why-us-home .ex-vectors {
  position: absolute;
  transform: rotate(90deg);
  top: 300px;
  right: -10%;
  fill-opacity: 0.2;
}
.why-us-home .ex-vectors {
  gap: 10px;
}
.why-us-home .ex-vectors svg {
  width: 40px;
}

.why-us-home .vector-element1,
.vector-element2 {
  gap: 50px;
}
/*-------------- MEDIA QUIRIES ---------------------------------------------- */

@media (max-width: 1280px) {
  .why-us-wrap {
    gap: 10px;
  }
  .why-text {
    line-height: 22px;
    padding: 10px 60px 10px 0;
  }

  .why-us-home .ex-vectors {
    top: 300px;
    right: -12%;
  }
  .why-us-home .ex-vectors {
    gap: 8px;
  }
  .why-us-home .ex-vectors svg {
    width: 30px;
  }

  .why-us-home .vector-element1,
  .vector-element2 {
    gap: 50px;
  }

  .counter-detail:after {
    width: 100px;
    height: 1px;
    bottom: 80px;
    right: -120px;
  }

  .why-us-grid img {
    width: 58%;
    height: 80%;
  }

  .why-us-grid img:nth-child(2) {
    width: 58%;
    margin-bottom: 10px;
  }
}
@media (max-width: 1080px) {
  .counter-detail {
    margin: 1em auto;
  }

  .why-us-home .ex-vectors {
    top: 280px;
    right: -10%;
  }

  .why-us-home-bg {
    height: 80%;
  }
  .why-us-home .ex-vectors svg {
    width: 30px;
  }

  .why-us-home .vector-element1,
  .vector-element2 {
    gap: 40px;
  }
  .why-us-home .home-stroke {
    font-size: 60px;
    line-height: 80px;
  }
  .counter-detail:after {
    width: 60px;
    height: 2px;
    bottom: 80px;
    right: -60px;
  }
  .why-us-home .home-heading,
  .why-us-home .home-title {
    line-height: 24px;
  }
  .why-us-wrapper-right {
    gap: 10px;
  }
  .why-text {
    line-height: 18px;
  }

  .why-us-grid img {
    width: 58%;
    height: 80%;
  }
}

@media (max-width: 950px) {
  .why-us-wrap,
  .why-wrap-secondary {
    flex-direction: row-reverse;
    justify-content: space-around;
    gap: 0;
    height: 500px;
  }
  .why-us-home .ex-vectors {
    top: 200px;
    right: -12%;
  }
  .why-us-home .ex-vectors svg {
    fill: #161d27;
    width: 40px;
  }

  .why-us-home .vector-element1 svg:nth-last-child(2) {
    stroke: #66fa93;
    stroke-width: 4px;
  }

  .why-us-wrapper-right {
    flex: 1;
    padding: 20px;
    margin-right: -380px;
    z-index: 2;
  }
  .why-us-wrapper-left,
  .why-us-wrapper-right {
    flex: 1;
    /* margin-left: -20px; */
  }
  .why-us-wrapper-left-grid {
    flex: 1;
    height: 100%;
  }
  .why-us-home-bg {
    width: 100%;
    height: 80%;
    opacity: 0.5;
    border-radius: 24px 26px 0px 80px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .why-us-home-bg img {
    border-radius: 24px 26px 0px 80px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .why-text {
    line-height: 24px;
    padding: 10px;
  }

  /* our talents why us  */

  .why-us-grid {
    height: 100%;
    opacity: 0.6;
    align-items: center;
  }

  .why-us-grid img:nth-child(2) {
    display: none;
  }
  .why-us-grid img {
    width: 100%;
    height: 100%;
  }
  .why-text-secondary {
    line-height: normal;
  }

  .why-us-grid img:nth-child(1) {
    display: none;
  }
  .why-us-grid img:nth-child(3) {
    display: flex;
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: top right;
    border-radius: 0px 0px 0px 800px;
    border: none;
  }
}
@media (max-width: 600px) {
  .why-us-home .home-stroke {
    font-size: 50px;
    line-height: 50px;
  }
  .counter-detail:after {
    width: 40px;
    height: 2px;
    bottom: 60px;
    right: -40px;
  }
  .why-us-home .home-heading,
  .why-us-home .home-title {
    line-height: 20px;
  }

  .why-us-home .ex-vectors {
    top: 250px;
    right: -26%;
  }
  .why-text {
    line-height: 20px;
  }
  .why-text-secondary {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
@media (max-width: 500px) {
  .why-us-home .home-stroke {
    font-size: 40px;
    line-height: 40px;
  }
  .counter-detail:after {
    width: 38px;
    height: 2px;
    bottom: 60px;
    right: -38px;
  }
  .why-text-secondary {
    font-size: 12px;
    font-weight: 600;
  }
  .why-werkix-text2 {
    display: none;
    background: #000;
  }
}
@media (max-width: 420px) {
  .why-us-counter {
    display: none;
  }
  .why-us-home .ex-vectors {
    top: 250px;
    right: -40%;
  }
  .why-text {
    line-height: 16px;
    padding: 10px;
  }
}
