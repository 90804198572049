.main-header {
  background: #161d27db;
  z-index: 2;
}

.top-header {
  display: flex;
  align-items: center;
  background: #161d27;
  width: 100%;
  height: 40px;
  z-index: 2;
}

.top-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1600px;
}
.location {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #66fa93;
}
.location svg {
  fill: #66fa93 !important;
}
.socials {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}
.top-header .socials svg {
  fill: #66fa93;
}
.social-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  background: #161d2723;
}

/* MOBILE NAV HEADER --------------------- */
.mobile-header {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  /* height: 32px; */
  height: 64px;

  background: #7ac14b;
}
.mobile-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 40px; */
}
.mobile-menu svg {
  width: 80px;
}

/* .mobile-header svg {
  transform: translateX(20px);
} */
/*--------------  MEDIA QUERRIES ------------------ */
@media (max-width: 850px) {
  .nav-linkk {
    font-size: 16px;
    line-height: normal;
  }
}

@media (min-width: 820px) {
  .mobile-header {
    display: none;
  }
  .mobile-nav-container {
    visibility: hidden;
  }
}
@media (max-width: 500px) {
  .navv {
    display: none;
  }

  .top-header {
    display: none;
  }
}
