.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  border-radius: 50%;
}

.bg-primary {
  background-color: #66fa93;
}
.bg-secondary {
  background-color: #66fa9221;
}

.bg-primary:hover,
.bg-secondary:hover {
  opacity: 0.3;
  cursor: pointer;
}
