.home {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 100px;
  background: #0c0f14;
}
.home .why-us-home {
  margin-top: -140px;
}
@media (max-width: 960px) {
  .home {
    gap: 100px;
  }
}
@media (max-width: 768px) {
  .home {
    gap: 80px;
  }
}
@media (max-width: 500px) {
  .home {
    gap: 40px;
  }
}
@media (max-width: 500px) {
  .home .why-us-home {
    margin-top: -50px;
  }
}
