.talent-testimonials {
  width: 100%;
  height: fit-content;
  /* height: 900px; */

  opacity: 0.97;
  background: #161d27;
  padding: 100px 0;
}
.talent-container {
  padding: 40px;
}

.section-header {
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateY(-100px);
}
.testimonials {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/*====================== MEDIA QUIRIES =======================  */

@media (max-width: 650px) {
  .talent-testimonials .section-header {
    padding: 0;
  }
  .talent-testimonials .home-heading {
    font-size: 24px;
    margin-top: 0;
  }
}

@media (min-width: 650px) {
}
