.banner {
  width: 100%;
  height: fit-content;
  overflow-x: hidden;
}

.banner-wrap {
  position: relative;
}

.banner-image {
  position: relative;
  width: 100%;
  height: 900px;
  background-image: url("https://res.cloudinary.com/dhbe6wtod/image/upload/v1718358091/werkix/home/hero-images_pkpxat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  opacity: 0.15;
}

.banner-image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.55); */
}
.banner-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 25%;
  left: 10%;
  /* transform: translate(-50%, -50%); */
  text-align: left;
}

.mission {
  color: #66fa93;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  text-transform: uppercase;
}

.empower {
  width: 100%;
  font-weight: 700;
  font-size: 100px;
  text-transform: uppercase;
  color: #fff;
}
.content-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  width: 607px;
  height: 112px;
  color: #fff;
  margin-bottom: 14px;
}
.phone {
  display: flex;
  align-items: center;
  gap: 8px;
  /* font-family: Manrope; */
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  width: 607px;
  height: 30px;
  color: #fff;
  padding: 4px 0;
  z-index: 3;
}

/* $##########V ECTORS  ##########*/

/*-------------- VECTORS ---------------------------------------------- */

.banner-wrap .ex-vectors {
  position: absolute;
  bottom: 70px;
  left: 60%;
  transform: translateX(-50%);
  gap: 2px;
}

.banner-wrap .ex-vectors svg:hover {
  stroke: #0086e0;
}

.banner-wrap .vector-element1,
.banner-wrap .vector-element2 {
  gap: 18px;
}
.banner-wrap .vector-element1 svg:nth-child(2),
.banner-wrap .vector-element2 svg:nth-last-child(2) {
  fill: #66fa93;
}
.banner-wrap .vector-element1 svg:nth-child(6),
.banner-wrap .vector-element2 svg:nth-child(1) {
  visibility: visible;
}
.banner-wrap .ex-vectors svg {
  width: 30px;
  opacity: 1;
  fill-opacity: 0.9;
  stroke: none;
  fill: rgba(255, 255, 255, 0.1);
}
.banner-wrap .ex-vectors svg:nth-child(2) {
}

.bottom-left-vectors {
  position: relative;
}
.bottom-left-vectors svg {
  position: absolute;
  left: -2%;
  bottom: 10.4%;
  z-index: 1;
}

.middle-vectors {
  position: relative;
}
.middle-vectors svg {
  position: absolute;
  left: -1%;
  top: -840px;
  z-index: 1;
}

.bottom-right-vectors {
  position: absolute;
  z-index: 2;
  right: 1%;
  bottom: 60px;
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */
@media (max-width: 1240px) {
  .banner-wrap .vector-element1 svg:nth-child(2) {
    fill: rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 870px) {
  .banner-content {
    top: 20%;
  }

  .empower {
    font-size: 90px;
  }
}
@media (min-width: 820px) {
  .phone svg:nth-child(2) {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 820px) {
  .banner-content {
    top: 10%;
    gap: 20px;
  }
  .content-text {
    width: 96%;
  }
  .banner-wrap .ex-vectors {
    display: none;
  }

  .empower {
    font-size: 80px;
  }
}
@media (max-width: 680px) {
  .banner {
    height: fit-content;
  }
  .banner-image {
    height: 400px;
  }

  .banner-content {
    justify-content: center;
    gap: 0;
  }
  .empower {
    font-size: 40px;
  }
  .content-text {
    font-size: 14px;
  }
  .phone {
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
  .phone svg:nth-child(2) {
    display: none;
  }
  .banner-wrap .bottom-left-vectors,
  .banner-wrap .bottom-right-vectors {
    display: none;
  }
  .banner-wrap .ex-vectors {
    display: flex;
    bottom: 34%;
    transform: translateX(-10%);
  }
  .banner-wrap .ex-vectors svg {
    width: 20px;
  }
}

@media (max-width: 540px) {
  .banner {
    height: fit-content;
  }
  .banner-image {
    height: 380px;
  }
  .banner-content {
    gap: 0px;
  }

  .content-text {
    font-size: 12px;
  }
  .empower {
    font-size: 40px;
  }
}
@media (max-width: 400px) {
  .banner {
    height: fit-content;
  }
  .banner-image {
    height: 450px;
  }
}
