/* NotFoundPage.css */

.not-found-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #1c1c1c;
  color: #fff;
}

.not-found-page h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.not-found-page p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.home-link {
  text-decoration: none;
  color: #fff;
  background-color: #28a745;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-link:hover {
  background-color: #218838;
}
