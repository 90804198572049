.contact-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  width: 306px;
  height: 180px;
  padding: 10px 4px;

  border-radius: 24px;
  border: 2px solid #242a32;
  background: #0c0f14;
  shrink: 0;
}
.contact-card p {
  padding: 0;
  margin: 0;
}
.locationCard {
  height: 240px;
}
.contact-card p {
  color: #fff;
  text-align: center;
  font-family: "Titillium Web";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
}
.contact-cardBtn {
  position: absolute;
  top: -26px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;

  border: 1px solid #171d26;
  background: #171d26;
  border-radius: 50%;
  /* stroke: var(--Style, #171d26); */
}

.card-number {
  color: #bcbcbc;
  text-align: center;
  font-family: "Sora";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}

.contact-card:hover {
  border: 1px solid #67fb94;
}
.contact-card:hover .contact-cardBtn {
  color: #0c0f14;
  background: #67fb94;
}
.contact-card:hover .card-number {
  color: #0c0f14;
}

.contact-card svg {
  shrink: 0;
}

@media (max-width: 1200px) {
  .contact-card p {
    font-size: 16px;
  }
}
@media (max-width: 1080px) {
  .contact-cards {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
}
@media (max-width: 830px) {
  .contact-cards {
    flex-wrap: nowrap;
    height: 200px;
    gap: 20px;
  }
  .contact-card {
    padding: 5px;
    gap: 10px;
  }
  .contact-cards .contact-card:nth-child(4) {
    display: none;
  }

  .contact-card i svg {
    width: 45px;
  }
  .contact-card p {
    font-size: 13px;
    line-height: normal;
    font-weight: 600;
  }
}

@media (max-width: 650px) {
  .contact-cards {
    gap: 10px;
  }
  .contact-card {
    height: 100px;
    padding: 8px;
  }
  .contact-card:nth-child(3) {
    width: 500px;
  }
  .contact-card i svg {
    width: 35px;
  }
  .contact-card p {
    font-size: 14px;
    font-weight: 500;
  }

  .contact-cardBtn {
    display: none;
  }
  .card-number {
    line-height: normal;
  }
}

@media (max-width: 560px) {
  .contact-cards {
    gap: 8px;
  }

  .contact-card i {
    width: 30px;
    height: 30px;

    gap: 2px;
    padding: 4px;
  }
  .contact-card i svg {
    width: 20px;
  }
  .contact-card p {
    font-size: 10px;
    font-weight: 600;
  }
}
