.page {
  position: relative;
  height: 319px;
}

.page-header-bg {
  width: 100%;
  height: 100%;
  background: url("https://res.cloudinary.com/dhbe6wtod/image/upload/v1721232802/werkix/home/header-img-secondary_m6a05y.jpg")
    lightgray -4px -63.656px / 100% 228.938%;
  background-size: cover;
  background-position: center top;
}
.page-header-overlay {
  height: 100%;
  width: 100%;

  opacity: 0.7;
  background-color: #0c0f14;

  display: flex;
  justify-content: center;
  align-items: center;
}
.page .page-heading {
  transform: translateY(-60px);
  opacity: 0.2;
}
@media (max-width: 820px) {
  .page .page-heading {
    font-size: 50px;
  }
}
