.blog {
  max-width: 830px;
  color: #fff;
  margin-top: 100px;

  background: #161d27;
}
.article-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.article-img {
  width: 100%;
  height: 470px;

  border-radius: 24px 24px 0px 0px;
}
.article-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 24px 24px 0px 0px;
}

.article-details {
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 20px;
}
.blog-heading {
  color: var(--White, #fff);
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  text-transform: capitalize;

  padding: 0;
  margin: 0;
}
.blog-desc {
  color: #c0c0c2;

  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 0;
  margin: 0;
}

.profile-img {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  border-radius: 50%;
}
.blog-redirect {
  display: flex;
  justify-content: space-between;
}
.blogger-details {
  display: flex;
  gap: 10px;
}

.name-position {
  display: flex;
  flex-direction: column;
  color: #fff;
  transform: translateY(10px);
}
.name-position .name {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}
.name-position .position {
  color: #c0c0c2;

  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.redirect-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #66fa93;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-decoration: none;

  width: 150px;
  height: 50px;

  border-radius: 24px;
  border: 1px solid var(--Primary, #66fa93);
}

.redirect-btn:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .article-container .article-img {
    height: 400px;
  }
  .blog {
    border-radius: 24px;
  }
}
@media (max-width: 600px) {
  .article-container .article-img {
    height: 350px;
  }
  .blog .blog-heading {
    font-size: 18px !important;
    line-height: normal;
  }
  .blog .blog-desc {
    line-height: normal;
  }
  .redirect-btn {
    font-size: 14px;
    line-height: normal;
    height: 40px;
  }

  @media (max-width: 600px) {
    .article-container .article-img {
      height: 350px;
    }
    .blog .blog-heading {
      font-size: 18px !important;
      line-height: normal;
    }
    .blog .blog-desc {
      line-height: normal;
    }
    .redirect-btn {
      font-size: 14px;
      line-height: normal;
      height: 40px;
    }

    .name-position .name {
      font-size: 16px;
      line-height: normal;
    }
    .name-position .position {
      font-size: 16px;
      line-height: normal;
    }
  }
}

@media (max-width: 500px) {
  .article-container .article-img {
    height: 300px;
  }

  .redirect-btn {
    font-size: 14px;
    line-height: normal;
    height: 35px;
  }

  .name-position .name {
    font-size: 14px;
  }
}
