.ourTalent-page {
  position: relative;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  margin: 0 auto;

  gap: 100px;
  background: #0c0f14;
}

.services-container {
  width: 90%;
  max-width: 1400px;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  gap: 50px;
  padding: 50px 0px;
}
.join-process {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 20px;
}

.ourTalent-text-slider .sliding-text {
  -webkit-text-stroke: 1px #fff;
}

.team-members {
  width: 100%;
  height: fit-content;
  margin: 0 auto;
}
.team-members .head-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}
.team-members .head-section h3 {
  color: #7ac14b;
  text-align: right;

  font-size: 42px;
  font-weight: 700;
  line-height: 62px;
  text-transform: capitalize;

  padding: 10px 50px;
}

.team-members-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  max-width: 1450px;
}

/* VECTORS ===================== */
.team-member-vector {
  position: absolute;
  left: 0;
  /* bottom: 10px; */
}
.team-members .ex-vectors {
  display: flex;
  flex-direction: column;
  gap: 20px;
  fill-opacity: 0.2;
}
/* CONTACT FORM  */

.ourTalent-page .contact-form-container {
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ourTalent-page .contact-form {
  width: 90%;
}

.fill-form {
  color: #66fa93;

  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
  text-transform: capitalize;
}
.form-desc {
  color: var(--Grey, #c0c0c2);

  /* Paragraph */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.attach-cv-button {
  position: relative;

  display: flex;
  width: 257px;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: #fff;
  font-family: "Sora";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;

  border: 2px solid #67fb94;

  padding: 10px 20px;
}
.attach-cv-button svg {
  stroke: #67fb94;
}
.attach-cv-button:hover {
  opacity: 0.5;
  cursor: pointer;
}
.attach-cv-square {
  position: absolute;
  width: 9px;
  height: 9px;
  flex-shrink: 0;

  border: 2px solid #67fb94;
}
.attach-cv-square:nth-child(1) {
  top: -10px;
  left: -13px;
}
.attach-cv-square:nth-child(2) {
  top: -10px;
  right: -13px;
}
.attach-cv-square:nth-child(3) {
  bottom: -10px;
  left: -13px;
}
.attach-cv-square:nth-child(4) {
  bottom: -10px;
  right: -13px;
}
.file-list {
  color: #fff;
}
.file-list svg {
  width: 40px;
}

/* JOB CATEGORY CARD  */
.contact-jobs {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  color: #fff;

  width: 340px;
  height: 380px;

  border-radius: 24px;
  border: 1px solid #848484;
  background: #0c0f14;
  box-shadow: 0px 4px 8px 0px rgba(103, 251, 148, 0.1);

  padding: 20px;

  /* align-self: flex-end; */
  transform: translateY(40px);
}
.category {
  text-decoration: none;
  color: #c0c0c2;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 20px;
}
.job-count {
  color: #c0c0c2;
}
.category:hover {
  border-radius: 24px;
  border: 1px solid #66fa93;
}
.job-category {
  display: flex;
  flex-wrap: wrap;
}

.job-title {
  color: #66fa93;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.linkedin-redirect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 16px;
  gap: 26px;

  width: 80%;
  border-radius: 24px;
  border: 1px solid #67fb94;

  text-decoration: none;
  color: #67fb94;
}
.linkedin-redirect:hover {
  opacity: 0.5;
}

.highlighter {
  position: absolute;
  right: 0px;
  top: 100px;

  width: 4px;
  height: 80px;

  border-radius: 8px;
  background: #66fa93;
}

/* INPUT AND FORM OUR TALENT PAGE SPECIFICS */
.ourTalent-page .name-email input {
  height: 46px;
}

.file-list,
.hidden-input {
  display: none;
}

/* MEDIA QUIRIES ==================  */

@media (max-width: 1340px) {
  .ourTalent-page .name-email input {
    width: 94%;
  }
}

@media (max-width: 1080px) {
  .contact-jobs {
    width: 300px;
  }
  .contact-jobs h2 {
    font-size: 20px;
    line-height: normal;
  }
  .contact-jobs .category {
    font-size: 14px;
    font-weight: 500;
  }
  .contact-jobs .job-breakdown {
  }

  .linkedin-redirect {
    gap: 16px;
    padding: 10px 14px;
    font-size: 14px;
    line-height: normal;
  }

  .ourTalent-page .team-member-picture {
    height: 72%;
  }
}

@media (max-width: 945px) {
  .ourTalent-page .contact-jobs {
    width: 280px;
  }

  .contact-jobs h2 {
    font-size: 18px;
    line-height: normal;
  }
  .contact-jobs .category {
    font-size: 14px;
    font-weight: 500;
  }

  .linkedin-redirect {
    width: 70%;
    font-size: 12px !important;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}
@media (max-width: 900px) {
  .ourTalent-page .team-member-picture {
    height: 66%;
  }
}

@media (max-width: 800px) {
  .ourTalent-page .contact-form-container {
    width: 80%;
  }

  .ourTalent-page .contact-form {
    width: 100%;
  }

  .ourTalent-page .contact-jobs {
    display: none;
  }
}

@media (max-width: 768px) {
  .team-members .head-section h3 {
    font-size: 30px;
    line-height: 50px;
  }

  .ourTalent-page::-webkit-scrollbar {
    display: none;
  }

  .ourTalent-page .swipe {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .ourTalent-page .form-desc {
    font-size: 16px;
    line-height: normal;
  }
  .ourTalent-page .contact-form .name-email {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .ourTalent-page .contact-form .name-email .input-wrap input {
    width: 100%;
    height: 40px;
  }
  .ourTalent-page .contact-form textarea {
    height: 100px;
  }
  .ourTalent-page .submit-btn {
    width: 200px;
    height: 56px;
    line-height: normal;
    font-size: 14px;
  }
  .submit-btn svg {
    width: 14px;
  }
  .ourTalent-page .fill-form {
    font-size: 20px;
  }

  .ourTalent-page .contact-form {
    gap: 20px;
  }
}
@media (max-width: 530px) {
  .swipe {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0px);
  }
  .join-process {
    gap: 10px;
  }

  .attach-cv-button {
    width: 50%;
  }
}

.profile {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */

@media (max-width: 600px) {
  .home-heading {
    opacity: 0.2;
  }
}
@media (max-width: 700px) {
  .profile {
    gap: 10px;
  }
}
@media (max-width: 550px) {
  .profile {
    gap: 8px;
  }
}
