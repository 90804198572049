.case-studies-home {
  position: relative;
  height: 900px;
  padding: 0 18px;
}

.case-studies-wrap {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}
.case-studies-header {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.left-header {
  width: 60%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.right-header {
  display: flex;
  gap: 1em;
}
.leftArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  background-color: #ffffff06;
  box-shadow: drop-shadow(0px 6px 50px rgba(37, 37, 37, 0.05));
  border-radius: 50%;
}
.rightArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  background-color: #66fa9210;
  box-shadow: drop-shadow(0px 6px 50px rgba(37, 37, 37, 0.05));
  border-radius: 50%;
  transform: rotate(180deg);
}

.rightArrow:hover,
.leftArrow:hover {
  cursor: pointer;
  opacity: 0.5;
}

.case-studies-content {
  height: 568px;
  display: flex;
  gap: 10px;
}

.slider-container {
  height: 100%;
  border-radius: 25px;
  flex: 1;
}
.slider-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  opacity: 0.5;
}
.slider-container img:hover {
  border: 1px solid #67fb94;
  opacity: 0.4;
}
.slider {
  position: relative;
  height: 100%;
}
.grid-item {
  position: relative;
  flex: 1;
  height: 50%;
}
.row-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.case-desc {
  display: flex;
  flex-direction: column;
  position: absolute;

  bottom: 60px;
  left: 20px;
}
.case-study-heading {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}
.case-study-subheading {
  color: #66fa93;
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
}

.case-study-bg {
  position: absolute;
  left: 0px;
  top: -40px;
  height: 100%;
  width: 30%;
  flex-shrink: 0;
  border-radius: 40px 0px 0px 0px;
  background: #161d27;
  z-index: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.case-study-exes {
  position: absolute;
  top: 20%;
}
.case-study-exes svg {
  width: 100px;
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */

@media (max-width: 1080px) {
  .case-studies-content {
    height: 468px;
  }

  .case-study-bg {
    height: 80%;
  }
  .case-study-exes {
    top: 10%;
  }
  .case-study-exes svg {
    width: 80px;
  }
}
@media (max-width: 945px) {
  .case-studies-content {
    height: 400px;
  }
  .case-study-heading {
    font-size: 20px;
  }
  .case-desc {
    bottom: 20px;
  }
  .case-study-exes svg {
    width: 60px;
  }
}

@media (max-width: 800px) {
  .case-studies-home {
    height: 600px;
  }
  .case-studies-content {
    height: 300px;
  }
  .right-header {
    display: none;
  }

  .case-study-bg {
    height: 60%;
  }

  .case-study-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
  }
  .case-desc {
    bottom: 10px;
  }

  .case-study-exes {
    top: -12%;
  }
  .case-study-exes svg {
    width: 40px;
  }
  .slider-container:nth-last-child(1) {
    display: none;
  }
  .case-desc {
    bottom: 20px;
  }
}
@media (max-width: 500px) {
  .case-study-subheading {
    line-height: normal;
  }
  .case-study-heading {
    font-weight: 400;
  }

  .case-studies-home .home-heading {
    line-height: 28px;
  }
}
@media (max-width: 400px) {
  .case-study-subheading {
    font-size: 10px;
  }
  .case-study-heading {
    font-size: 14px;
    font-weight: 300;
    line-height: 12px;
  }
}
