.process-card {
  width: 250px;
  height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  gap: 1 0px;
  text-align: center;

  color: #fff;

  padding: 40px 20px;

  border: 1px solid #171d26;
  background: #0c0f14;
}

.process-card:hover {
  border: 1px solid #67fb94;
  border-radius: 24px;
}

.process-card:hover .process,
.process-card:hover .card-arrow {
  color: #0c0f14;
  background: #67fb94;
}

.process-card:hover .process-card svg,
.process-card:hover .card-arrow svg {
  stroke: #0c0f14;
}

.process-card .process {
  width: 246px;
  height: 49px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  background: #171d26;

  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.process-desc {
  color: #bcbcbc;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.card-arrow {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 70px;

  border: 1px solid #171d26;
  border-radius: 50%;
}
.card-arrow svg {
  stroke: #bcbcbc;
}

.process-card {
  fill: #bcbcbc;
}
.process-card:hover .card-icon svg {
  fill: #67fb94;
}

@media (max-width: 768px) {
  .process-card {
    width: 200px;
    height: 200px;

    /* justify-content: space-between; */
    padding: 20px;
    gap: 5px;

    border-radius: 16px;
    border: 0.5px solid #848484;
    background: #0c0f14;
  }

  .card-icon {
    width: 40%;
    height: 40%;
  }
  .card-icon svg {
    width: 35px;
  }
  .process-card .process {
    font-weight: 400;
    font-size: 12px;
    width: 90%;
    height: fit-content;
    padding: 10px;

    border-radius: 8px;
    background: #171d26;
  }

  .process-card .card-arrow {
    display: none;
  }
}

@media (max-width: 560px) {
  .process-card {
    width: 150px;
    height: 150px;
    padding: 10px;
  }
  .process-card .process-desc {
    display: none;
  }
}
@media (max-width: 400px) {
  .process-card {
    width: 120px;
    height: 120px;
    padding: 10px;
  }
}
@media (max-width: 330px) {
  .process-card {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 280px) {
  .process-card {
    width: 100%;
  }
}
