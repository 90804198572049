.navv {
  position: relative;
  width: 100%;
  height: 100px;
  background: #0c0f14;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-listt {
  display: flex;
  justify-content: center;
  gap: 40px;

  list-style: none;
}
.nav-linkk {
  color: #c0c0c2;
  position: relative;
  text-decoration: none;

  color: #c0c0c2;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}

.nav-linkk:hover {
  color: #66fa93;
}
.nav-linkk.active {
  color: #66fa93;
}
.nav-linkk.active::before {
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #66fa93;
  bottom: 0;
  transition: width 0.3s ease-in-out;
}

.nav-linkk.active::before {
  width: 20%;
}

/* MOBILE NAV MENU -------------------------- */
.mobile-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  z-index: 3;
  width: 80%;
  height: fit-content;

  border-radius: 24px;
  border: 1px solid #67fb94;
  background: #161d27;
  padding: 10px 20px;
}
.mobile-nav .nav-listt {
  display: flex;
  padding: 24px 160px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}
.mobile-nav .nav-linkk {
  color: #fff;

  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}
.close-icon {
  position: absolute;
  right: 24px;
  top: 24px;

  color: #67fb94;

  font-size: 30px;
  font-weight: 800;
  line-height: 25px;
  text-transform: capitalize;
}

/* DROP DOWN */
/* Dropdown container with smooth height and opacity transition */
.dropdown {
  width: 250px;
  border-radius: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #161d27db;
  display: block;
  padding: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  overflow: hidden;
  transition: opacity 0.3s ease, height 0.3s ease;
  z-index: 3;
}

/* nav-itemm:hover .dropdown {
  opacity: 1;
  height: auto;
  transition: opacity 0.3s ease, height 0.4s ease;
} */
.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-item {
  padding: 10px 20px;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeIn 0.4s ease forwards;
  font-size: 16px;
}

.dropdown-item:nth-child(1) {
  animation-delay: 0.1s;
}
.dropdown-item:nth-child(2) {
  animation-delay: 0.2s;
}
.dropdown-item:nth-child(3) {
  animation-delay: 0.3s;
}
.dropdown-item:nth-child(4) {
  animation-delay: 0.4s;
}
.dropdown-item:nth-child(5) {
  animation-delay: 0.5s;
}
.dropdown-item:nth-child(6) {
  animation-delay: 0.6s;
}
.dropdown-item:nth-child(7) {
  animation-delay: 0.7s;
}
.dropdown-item:nth-child(8) {
  animation-delay: 0.8s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-item:hover {
  background-color: #555;
  color: #00ff88;
  font-weight: bold;
}
.dropdown-item.active {
  font-weight: bolder;
}
.dropdown-item.active::before {
  content: "";
  position: absolute;
  width: 0;
  height: 30px;
  background-color: #fbfdfc;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.dropdown-item.active::before {
  width: 1.2%;
}

/* Accordion Styles */
.accordion-item {
  cursor: pointer;
  color: white;
}

.accordion-header {
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 10px;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.accordion-header:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.accordion-content {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  text-align: left;
}

.accordion-link {
  font-size: 16px;
  padding: 8px 10px;
  border-bottom: 1px solid #ffffff20;
  font-weight: 600;
}

.accordion-link:hover {
  color: #ffffff;
}

/* .expanded .accordion-header {
  background-color: rgba(255, 255, 255, 0.2);
} */

@media (max-width: 875px) {
  .nav-linkk {
    color: #c0c0c2;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
  }
}
