@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif, "Sora", "Titillium Web";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #0c0f14; */
  overflow-x: hidden;
  background: #0c0f14;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

:root {
  --transition-1: 500s ease-in-out;
  --opacity50: 50%;
}
.container {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.home-title {
  color: #66fa93;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
}
.home-heading {
  color: #fff;
  font-size: 42px;
  font-weight: 600;
  line-height: 62px;
  text-transform: capitalize;
}
.home-stroke {
  -webkit-text-stroke: 1px white;
  opacity: 0.3;
  font-size: 100px;
  font-weight: 700;
  line-height: 100px;
  text-transform: uppercase;
}

.home-stroke-subtitle {
  color: #66fa93;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.subheading {
  font-family: Inter;
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  line-height: 35px;
  text-transform: capitalize;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.page-heading {
  text-align: center;

  -webkit-text-stroke: 4px #fff;
  color: transparent;
  font-family: Inter;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 100px;
  text-transform: uppercase;
}
.subtitle-plain {
  color: #fff;
  font-family: "Titillium Web";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.paragraph-text {
  color: #c0c0c2;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0.1;
}

.testimonial-divider {
  width: 100%;
  height: 2px;
  background: #67fb94;
}

@media (max-width: 820px) {
  .navv {
    display: none !important;
  }

  .top-header {
    display: none !important;
  }
}
