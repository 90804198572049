.about-home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: fit-content;
}
.about-home-wrap {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 80px 40px;
}

.about-left {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.about-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.images-wrap1 {
  width: 587px;
  height: 483px;
}
.images-wrap1 img {
  opacity: 0.6;
  border-radius: 80px 103px 0px 261px;
}
.images-wrap2 {
  margin-left: -100px;
  width: 174px;
  height: 257px;
  z-index: 1;
}
.images-wrap2 img {
  border-radius: 87px;
  border: 3px solid var(--Primary, #66fa93);
}

.about-right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 30px;
}

.about-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
  max-width: 80%;
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */
@media (max-width: 1240px) {
  .about-right {
    gap: 10px;
  }
  .home-heading {
    font-size: 30px;
    line-height: 40px;
  }
  .home-title {
    font-size: 18px;
  }
  .about-text {
    line-height: 20px;
    max-width: 100%;
  }
}
@media (max-width: 1080px) {
  .images-wrap1 {
    width: 487px;
    height: 383px;
  }

  .images-wrap2 {
    width: 144px;
    height: 200px;
  }

  .about-right {
    gap: 0px;
  }
  .home-heading {
    font-size: 24px;
  }
  .home-title {
    font-size: 16px;
  }
  .about-text {
    line-height: 20px;
    max-width: 100%;
  }
}
@media (max-width: 920px) {
  .images-wrap2 {
    display: none;
  }

  .images-wrap1 {
    width: 387px;
    height: 280px;
  }
  .home-heading {
    font-size: 20px;
  }
  .home-title {
    font-size: 16px;
    line-height: 18px;
  }
  .about-text {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
  }
  .about-right {
    padding: 10px;
  }
}
@media (max-width: 760px) {
  .images-wrap1 {
    width: 247px;
    height: 141px;

    border-radius: 0px 80px;
    border: 1px solid #66fa93;
  }
  .images-wrap1 img {
    border-radius: 0px 80px;
    border: 1px solid #66fa93;
  }
  .about-home-wrap {
    padding: 20px 10px;
    width: 100%;
  }
  .about-right {
    flex: 2;
  }
  .about-left {
    flex: 1;
    padding: 0;
  }

  .about-text {
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 500px) {
  .about-home-wrap {
    flex-direction: column-reverse;
  }

  .images-wrap1 {
    width: 80%;
    height: 200px;
  }
}
/* 
@media (max-width: 640px) {
  .images-wrap1 {
    width: 220px;
    height: 121px;
    margin-left: -20px;
  }
  .about-home-wrap {
    padding: 20px 0;
  }
  .about-left {
    align-self: flex-start;
    transform: translateY(20px);
  }
  .about-text {
    font-weight: 14px;
    line-height: 12px;
  }
} */
