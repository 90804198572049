.team-member-card {
  width: 100%;
  max-width: 350px;
  height: 550px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-decoration: none;
}
.card-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.team-member-picture {
  height: 76%;
  border-radius: 24px;
}
.team-member-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;

  border-radius: 24px;
  opacity: 0.9;
}
.team-member-details {
  height: 20%;
  background: #161d27;
  border-radius: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.member-info {
  display: flex;
  flex-direction: column;
}
.member-info .name {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}

.member-info .position {
  color: #67fb94;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.member-info .secondary-pos {
  color: #fff;
  font-weight: 400;
  line-height: 30px;
}

.member-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #ffffff11;
  border-radius: 50%;
}

@media (max-width: 1190px) {
  .team-member-card {
    width: 25%;
    height: 400px;
  }

  .member-arrow {
    width: 40px !important;
    height: 40px !important;
    flex-shrink: 0;
  }

  .member-arrow svg {
    width: 16px;
  }

  .member-info .name {
    font-size: 16px;
    line-height: normal;
  }

  .member-info .position {
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media (max-width: 990px) {
  .team-member-card {
    height: 320px;
  }

  .member-arrow {
    width: 35px !important;
    height: 35px !important;
    flex-shrink: 0;
  }

  .member-arrow svg {
    width: 12px;
  }

  .member-info .name {
    font-size: 14px;
    font-weight: 700;
  }

  .member-info .position {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 882px) {
  .team-member-card {
    height: 260px;
  }
  .member-arrow {
    width: 30px !important;
    height: 30px !important;
    flex-shrink: 0;
  }

  .member-arrow svg {
    width: 10px;
  }

  .member-info .name {
    font-size: 12px;
  }

  .member-info .position {
    font-size: 12px;
  }
  .team-member-details {
    padding: 10px;
  }
}

@media (max-width: 660px) {
  .team-member-card {
    width: 26%;
    height: 220px;
  }
  .member-arrow {
    display: none;
  }
  .team-member-details {
    justify-content: start;
  }

  .member-info .name {
    font-size: 12px;
  }

  .member-info .position {
    font-size: 12px;
  }
}

@media (max-width: 560px) {
  .team-member-card {
    height: 160px;
  }

  .member-info .name {
    font-size: 10px;
  }

  .member-info .position {
    font-size: 8px;
  }
}
@media (max-width: 400px) {
  .team-member-card {
    width: 23%;
    height: 120px;
  }

  .member-info .name {
    font-size: 8px;
    font-weight: 500;
  }

  .member-info .position {
    display: none;
  }
}
/* 
@media (max-width: 960px) {
  .team-member-card {
    width: 350px;
    height: 450px;
  }

  .member-arrow {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 824px) {
  .team-member-card {
    width: 250px;
    height: 400px;
  }

  .member-info .name {
    font-size: 20px;
    line-height: 26px;
  }

  .member-info .position {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .member-arrow {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .team-member-card {
    width: 200px;
    height: 350px;
  }

  .member-info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .member-info .name {
    font-size: 14px;
    line-height: normal;
  }

  .member-info .position {
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
  }
}
@media (max-width: 500px) {
  .team-member-card {
    width: 180px;
    height: 250px;
  }
  .member-info {
    justify-content: center;
    padding: 20px 10px;
  }
  .member-info .name {
    font-size: 10px;
    font-weight: 700;
  }
  .member-info .position {
    font-size: 8px;
    font-weight: 700;
  }
}
@media (max-width: 445px) {
  .team-member-card {
    width: 120px;
    height: 180px;
  }

  .member-info {
    justify-content: center;
    padding: 20px 10px;
  }
  .member-info .name {
    font-size: 9px;
    font-weight: 600;
  }
  .member-info .position {
    display: none;
  }
} */
