.popular-posts-container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;

  max-width: 400px;
  height: 420px;
  padding: 80px 30px;
  border-radius: 8px;

  color: #fff;
  background-color: #161d27;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}
.popular-blogs-highlighter {
  position: absolute;
  left: 0;
  top: 54px;

  width: 2px;
  height: 38px;
  background: #66fa93;
}
.blog-card-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  align-self: flex-start;
}
.post {
  display: flex;
  gap: 10px;
}

.thumbnail {
  width: 120px;
  height: 80px;

  background: #d9d9d9;
  flex: none;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.content a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;

  text-decoration: none;
}

.content .date {
  display: flex;
  align-items: center;
  gap: 6px;

  color: #c0c0c2;

  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
}

.posts-error {
}
