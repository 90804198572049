.linkedin-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /* background: #fff !important; */
  color: #fff;
  height: 100vh !important;
}

.post {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

h2 {
  margin: 0;
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
}

.media {
  margin-top: 10px;
}

.media-item {
  margin-bottom: 10px;
}

.media-item img,
.media-item video {
  max-width: 100%;
  height: auto;
}

.comment {
  margin: 10px 0;
  border-top: 1px solid #eee;
  padding-top: 5px;
}

.comment p {
  margin: 5px 0;
}
