.about-page {
  background: #0c0f14;
  align-items: center;
  height: fit-content;
  padding-bottom: 100px;
  overflow-x: hidden;
}
.about-header {
  height: 400px;
  width: 100%;
}

.about-header-bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background: url("https://res.cloudinary.com/dhbe6wtod/image/upload/v1719420028/werkix/about-main/about-header-bg_vs8qau.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -126px;
  background-color: lightgray;
  width: 100%;
  /* height: 100%; */
  height: 100%;
}

.about-header-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 13, 13, 0.64);
  pointer-events: none;
}
.about-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  max-width: 1920px;
}
.about-page-gallery {
  width: 80%;
  height: 530px;
  display: flex;
  background: #0c0f14;
  margin: 0 auto;
  gap: 20px;
}
.about-one {
  display: none;
}
.about-page-gallery p {
  /* width: 80%; */
}
.about-page-right {
  flex: 1;
}
.about-page-left {
  flex: 1;
}
/* IMAGE IMAGE ===================== */
.image-container {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  padding: 20px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.image-gallery img {
  width: 50%;
  height: 50%;
  object-fit: cover;
  object-position: center;
}

.image-gallery :nth-child(1) {
  border-radius: 120.5px 0px 0px 0px;
  opacity: 0.6;
  width: 40%;
  height: 50%;
}
.image-gallery :nth-child(2) {
  z-index: 1;
  border-radius: 0px 0px 0px 160px;
  opacity: 0.8;
  height: 60%;
  /* height: 350px; */
}
.image-gallery :nth-child(3) {
  border-radius: 24px;
  border: 5px solid var(--Werkix-navy, #161d27);

  opacity: 0.5;
  width: 405px;
  margin-top: -80px;
}

.about-page-right {
  display: flex;
  flex-direction: column;
  flex-wrap: flex;
  width: 60%;
  color: #fff;
  width: 40%;
  height: fit-content;
}

/* services section  */
.services-section {
  color: #fff;
  width: 80%;
  margin: 50px auto 200px auto;
}

.service-subheading {
  color: #66fa93;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
}

.service-heading {
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 62px;
  text-transform: capitalize;

  margin-bottom: 70px;
}

.service-item {
  width: 100%;
  height: 280px;
  margin-bottom: 20px;
}

.service-divider {
  background: #fff;
  height: 1.5px;
  opacity: 0.1;
}

.service {
  display: grid;
  grid-template-columns: 1fr 3fr;
  place-items: center;
  height: 100%;
}
.service-description ul,
.service-description li {
  margin: 0;
  padding: 0;
}
.service-description {
  width: 60%;
}

.about-page-whyWerkix {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 150px;
}
.choose-werkix-summary li {
  list-style-position: outside;
  padding-left: 20px;
  margin-left: 20px;

  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}

.choose-werkix-summary li::marker {
  font-size: 6px;
}

.choose-werkix {
  padding-top: 60px;
  position: relative;
}
.choose-werkix-summary {
}
.choose-werkix-summary::before {
  content: "";
  position: absolute;
  bottom: 14px;
  width: 8px;
  height: 160px;
  border-radius: 8px;
  background: #67fb94;
}

.about-page-whyWerkix .home-heading {
  margin: 0;
  text-align: center;
  font-weight: bold;
}
.about-page-right .home-heading-mobile {
  display: none;
  font-weight: bold;
}
.our-cofounders {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 537px;
  margin: 0 auto;
}
.our-cofounders-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.words-cofounders {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex: 1;
  height: 80%;
  border-radius: 24px 240px 24px 24px;
  background: #161d27;
  margin-right: -140px;
  z-index: 2;
  padding: 20px 10px 40px 10px;
}
.words-cofounders .home-heading {
  padding: 0 20px;
}
.cofounders-desc .logo {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.cofounders-desc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px;
}
.cofounders-desc .logo {
}
.cofounders-desc .logo {
}
.cofounders-desc .logo {
}
.cofounders-video {
  flex: 1.8;
  border-radius: 24px;
  width: 100%;
  height: 100%;
}

/* ------------ VECTORS ---------------- */
.left-vector {
  position: absolute;
  left: -190px;
  bottom: -100px;
}

.left-vector svg {
}
.right-vector {
  position: absolute;
  right: 1%;
  opacity: 0.35;
  bottom: -120px;

  overflow: auto;
  z-index: 0;
}
.right-vector-mobile {
  display: none;
}
.right-vector svg {
  z-index: 0;
}

.gallery-vector {
  position: absolute;
  left: 40%;
  top: 2%;
  width: 100px;
  height: 100px;
}
.gallery-vector svg {
  width: 100%;
}
.gallery-vector:hover {
  opacity: 0.5;
  cursor: pointer;
}
.gallery-vector2 {
  position: absolute;
  left: 50px;
  top: 12%;
  width: 500px;
}
.gallery-vector2 svg {
  width: 100%;
}
/* =============== MEDIA QUIRIES =============================  */

@media (max-width: 1200px) {
  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;
  }

  .choose-werkix-summary::before {
    height: 200px;
  }
  .choose-card-wrap {
    grid-template-columns: 10% 86% !important;
  }
  .choose-card-wrapper {
    padding: 10px !important;
  }
  .choose-card-wrap li {
    font-size: 14px;
    line-height: 24px;
  }

  .words-cofounders .home-heading {
    line-height: 30px;
  }

  .words-cofounders .paragraph-text {
    line-height: 26px;
  }
  .words-cofounders .full-name {
    font-size: 18px;
    line-height: 24px;
  }
  .image-gallery :nth-child(1) {
    height: 40%;
  }
  .image-gallery :nth-child(2) {
    height: 300px;
  }
}

@media (max-width: 1080px) {
  .image-gallery :nth-child(1) {
    height: 30%;
  }
  .image-gallery :nth-child(2) {
    z-index: 1;
    border-radius: 0px 0px 0px 160px;
    opacity: 0.8;
    height: 250px;
  }

  .words-cofounders .home-heading {
    line-height: 26px;
  }

  .words-cofounders .paragraph-text {
    line-height: 26px;
  }
  .words-cofounders .full-name {
    font-size: 18px;
    line-height: 24px;
  }
  .words-cofounders .logo-container {
    width: 50px;
    height: 50px;
  }

  .words-cofounders .slider-btn {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 950px) {
  .about-page-right p {
    font-size: 14px;
  }

  .words-cofounders .home-heading {
    line-height: 24px;
  }

  .words-cofounders .paragraph-text {
    line-height: 24px;
  }
  .words-cofounders .full-name {
    font-size: 16px;
    line-height: 24px;
  }
  .slider-controls-container {
  }
  .words-cofounders .logo-container {
    width: 50px;
    height: 50px;
  }

  .words-cofounders .slider-btn {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 850px) {
  .about-page-gallery {
    display: flex;
    width: 100%;
  }
  .about-page-right {
    width: 100%;
    padding: 20px;
  }
  .image-gallery :nth-child(1) {
    width: 100%;
    height: 400px;
  }
  .image-gallery :nth-child(2) {
    display: none;
  }
  .about-content {
    padding-top: 20px;
  }
  .choose-card li:nth-last-child(1) {
    display: none;
  }
  .about-right-content p:nth-last-child(1) {
    display: none;
  }

  .about-right-content .home-heading {
    display: none;
  }
  .about-right-content .home-heading-mobile {
    display: flex;
    color: #d9d9d9;
    opacity: 0.3;
    font-size: 24px;
    font-weight: bold;
    margin: 5px 0;
  }
  .about-one {
    display: block;
    text-align: center;
  }

  .about-page-right {
    width: 100%;
    height: 100%;
  }
  .about-header-bg {
    background-position: 0px 0px;
  }
  .about-page-right p {
    font-size: 16px;
  }

  .image-gallery :nth-child(2) {
    z-index: 1;
    border-radius: 0px 0px 0px 160px;
    opacity: 0.8;
    height: 200px;
  }
  .image-gallery :nth-child(3) {
    opacity: 0.5;
    width: 450px;
    margin-top: -80px;
  }
  .choose-werkix-summary {
    line-height: normal;
  }
  .choose-werkix-summary::before {
    height: 200px;
  }
  .choose-card-wrap {
    grid-template-columns: 10% 86% !important;
  }

  .choose-card-wrap li {
    font-size: 14px;
    line-height: 20px;
  }
  .choose-card {
    border: none !important;
    border-radius: 24px;
    background: #161d27;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
  }
  .words-cofounders .home-heading {
    font-size: 16px;
  }

  .words-cofounders .paragraph-text {
    line-height: normal;
  }
  .words-cofounders .full-name {
    font-size: 16px;
    line-height: normal;
  }
  .slider-controls-container {
  }
  .words-cofounders .logo-container {
    gap: 10px;
  }

  .words-cofounders .slider-btn {
    width: 40px;
    height: 40px;
  }
  .gallery-vector {
    display: none;
  }

  .service-subheading {
    display: none;
  }
  .service-heading {
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center;
  }

  .divider-top {
    display: none;
  }

  .service {
    display: flex;
    flex-direction: column;
  }
  .service-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .service-description li:nth-child(1) {
    display: none;
  }
}
@media (max-width: 768px) {
  .words-cofounders {
    display: none;
  }
  .left-vector {
    display: none;
  }
  .our-cofounders-wrap {
    height: 350px;
  }
  .our-cofounders {
    width: 94%;
  }
  .right-vector {
    right: 1%;
    opacity: 0.35;
    bottom: -120px;
    overflow: hidden;
  }
}
@media (max-width: 712px) {
  .about-page-right p {
    font-size: 15px;
    padding: 0;
    margin: 0;
  }
  .choose-werkix {
    display: none;
  }

  .choose-card-wrap svg {
    display: none;
  }
  .choose-card-wrap {
    display: flex !important;

    border-radius: 24px;
    background: #161d27;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
  }
  .choose-card-wrapper {
    border: none !important;
    gap: 2px !important;
  }
  .choose-card-title {
    padding-left: 10px;
    margin-bottom: -12px;
  }
}
@media (max-width: 650px) {
  .about-content {
    margin-top: 20px;
  }

  .about-content .home-title {
    font-size: 14px;
  }

  .image-container {
    padding: 0;
    margin: 0;
  }
  .image-gallery :nth-child(1) {
    border-radius: 0px 120.5px 0px 0px;
    height: 350px;
    border: 1px solid #66fa93;
    margin-left: -5px;
    overflow: hidden;
  }

  .about-right-content p {
    font-size: 13px;
  }

  .about-one {
    display: flex;
    justify-content: center;
    padding: 12px;
  }

  .about-page .page-heading {
    font-size: 50px;
  }
  .choose-card-wrap li {
    line-height: normal;
  }
  .right-vector {
    display: none;
  }
  .last-card {
    z-index: 2;
  }
  .last-card li:nth-last-child(1) {
    display: block;
  }

  .right-vector-mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: 750px;
    /* overflow: hidden; */

    height: 300px;
  }
  .right-vector-mobile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .choose-card {
    width: 94% !important;
    z-index: 2;
  }
  .service-title h3 {
    font-size: 16px;
  }
  .service-description {
    width: 80%;
    font-size: 14px;
    color: #c0c0c2;
  }

  .image-gallery img:nth-child(1) {
    object-position: top right;
  }
}

@media (max-width: 460px) {
  .right-vector-mobile {
    bottom: 850px;
  }

  .service-description {
    font-size: 12px;
  }

  .image-gallery :nth-child(1) {
    height: 300px;
  }

  .about-right-content .home-heading-mobile {
    font-size: 18px;
    line-height: normal;
  }
  .about-right-content p {
    font-size: 12px;
    line-height: normal;
    font-weight: normal;
  }

  .about-content .home-title {
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
  }

  .service-description {
    width: 90%;
  }
}
