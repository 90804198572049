.blog-home {
  height: fit-content;
  /* min-height: 1050px; */
  width: 100%;
  /* max-width: 1350px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin: 0 auto;
  background: #0c0f14;
  padding-bottom: 4em;
}

.blog-wrap {
  width: 100% !important;
}
.blog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.blog-header {
  width: 100%;
  height: 250px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 20px 0;
  margin-bottom: -40px;

  border-radius: 0px 0px 240px 240px;
  background: var(--Werkix-navy, #161d27);
}
.header2 {
  width: 100%;
  height: 250px;
  background: var(--Werkix-navy, #161d27);
}
.blog-profile {
}
.blog-article {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 30px;
  width: 90%;
  max-width: 1350px;
  padding: 20px;
  /* max-width: 1400px; */
  margin: 0 auto;
}

@media (max-width: 1364px) {
  .blog-article {
    gap: 20px;
  }
}
