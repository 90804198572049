.carousel-container {
  width: 100%;
  max-width: 1600px;
  overflow: hidden;
  padding: 20px 0;
  height: 100px;
  margin: 0 auto;
  margin-top: -200px;
}

.carousel-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.client-logo {
  width: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* //pewani */
#logo1,
#logo5 {
  width: 60px;
}
/* //stratis */
#logo2 {
  margin-top: 20px;
}

.client-logo:hover {
  transform: scale(1.1);
}

/* ------------------------------      MEDIA QUERRIES    ------------------------------ */
@media (max-width: 1200px) {
  /* Rojoli */
  #logo3 {
    width: 100px;
  }
  .carousel-slide {
    width: 150px !important;
  }

  .carousel-slide img {
    object-fit: contain;
  }
}

@media (max-width: 860px) {
  .carousel-container {
    margin: 2px auto;
  }
  /* //pewani */
  #logo1,
  #logo5 {
    width: 40px;
  }
  /* //stratis */
  #logo2 {
    width: 80px;
  }
  /* Rojoli */
  #logo3 {
    width: 80px;
  }
  /* SF */
  #logo4 {
    width: 100px;
  }
  /* FSI */
  #logo6 {
    width: 100px;
  }
  .carousel-slide {
    width: 100px !important;
  }
}

@media (max-width: 680px) {
  /* //pewani */
  #logo1,
  #logo5 {
    width: 30px;
  }
  /* //stratis */
  #logo2 {
    margin-top: 10px;
    /* margin-bottom: 5px; */
    width: 60px;
  }
  /* Rojoli */
  #logo3 {
    width: 50px;
  }
  /* SF  */
  #logo4 {
    width: 90px;
  }

  /* Field scope */
  #logo6 {
    width: 80px;
  }
  .carousel-slide {
    width: 100px !important;
  }
}

@media (max-width: 400px) {
  /* //pewani */
  #logo1,
  #logo5 {
    width: 20px;
  }
  /* //stratis */
  #logo2 {
    margin-top: 10px;
    /* margin-bottom: 5px; */
    width: 40px;
  }
  /* Rojoli */
  #logo3 {
    width: 40px;
  }
  /* SF */
  #logo4 {
    width: 60px;
  }
  /*  */

  /* Field scope */
  #logo6 {
    width: 60px;
  }
  .carousel-slide {
    width: 80px !important;
  }
}
