.our-team-home {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c0f14;
}

.our-team-wrap {
  width: 100%;
}
.our-team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.our-team-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 40px 0;
}

.profile {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;

  width: 90%;
  max-width: 1450px;
  margin: 0 auto;
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */

@media (max-width: 600px) {
  .home-heading {
    opacity: 0.2;
  }
}

@media (max-width: 700px) {
  .profile {
    gap: 10px;
  }
}
@media (max-width: 550px) {
  .profile {
    gap: 8px;
  }
}
