.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 24px;
  overflow: hidden;
  opacity: 0.8;
  z-index: 1;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
}

.video-controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding: 10px;
  width: 67px;
  height: 67px;
  border-radius: 50%;
}
.video-controls:hover {
  cursor: pointer;
}
.video-controls button {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
}

.video-controls:hover {
  opacity: 0.5;
  cursor: pointer;
}

.full-screen-btn {
  position: absolute;
  bottom: 20%;
  right: 10%;
  transform: translate(-10%, -10%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  border: 2px solid black;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
