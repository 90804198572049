.input-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-wrap input {
  width: 100%;
  height: 50px;
  padding: 10px 20px;

  color: #c0c0c2;
  background: #0c0f14;
  border-radius: 16px;
  border: 2px solid #ffffff6d;

  font-size: 16px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.input-wrap ::placeholder {
  padding: 16px;

  color: #c0c0c2;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.input-wrap input:focus {
  border-color: #003242;
}

.input-error-border {
  border-color: #b94a48 !important;
}

.input-error {
  position: relative;
  padding: 10px 10px;
  background: #b94a48;
  width: 100%;
  color: #fff;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.input-success {
  position: relative;
  padding: 10px 10px;
  background: #161d27;
  width: 100%;
  color: #67fb94;
  font-size: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.input-wrap textarea {
  width: 100%;
  max-width: 100%;
  height: 150px;
  max-height: 150px;
  padding: 20px;

  color: #c0c0c2;
  background: #0c0f14;
  border-radius: 16px;
  border: 2px solid #ffffff6d;

  font-size: 16px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.input-wrap textarea::placeholder {
  color: #c0c0c2;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.input-wrap textarea::-webkit-scrollbar {
  width: 0.9em;
}
.input-wrap textarea::-webkit-scrollbar-thumb {
  background-color: #7272729a;
  border-radius: 20px;
}
