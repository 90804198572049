.no-memberr {
  color: #fff;
}
.team-member-detail {
  display: flex;
  flex-direction: column;
  gap: 100px;

  align-items: center;
  margin-top: 100px;
  padding: 50px 20px;
  color: white;
}

.detail-container {
  display: flex;

  width: 80%;
  max-width: 1200px;
  height: 650px;
  margin-bottom: 20px;

  border-radius: 24px;
  background: #161d27;
}

.member-photo-detail {
  flex: 1;
  height: 100%;
  border-radius: 24px 0px 0px 24px;
}

.member-photo-detail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;

  border-radius: 24px 0px 0px 24px;
}

.member-info-detail {
  flex: 1.4;
  color: #f5f5f5;
  padding: 10px 80px;
}

.description {
  width: 80%;
  color: #c0c0c2;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.contact-info h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  text-transform: capitalize;
}

.contact-info ul {
  list-style: none;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-info li {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact-info li strong {
  color: #fff;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.contact-method {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #c0c0c2;
}

.contact-icon {
  width: 50px;
  height: 50px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #2d343d;
  border-radius: 50%;
}

.contact-icon svg {
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */
@media (max-width: 1250px) {
  .detail-container {
    height: 570px;
  }

  .team-member-details {
    height: 16%;
  }

  .member-arrow {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1024px) {
  .detail-container .description {
    line-height: 22px;
  }
}

@media (max-width: 875px) {
  .detail-container {
    width: 90%;
    height: 490px;
  }
  .member-info-detail {
    padding: 20px 40px;
  }
  .contact-info h3 {
    font-size: 20px;
    line-height: 24px;
  }
  .detail-container .description {
    width: 100%;
  }

  .contact-info li strong {
    font-size: 18px !important;
    line-height: normal !important;
  }
}
@media (max-width: 775px) {
  .member-info-detail {
    padding: 20px 20px;
    line-height: normal;
  }
  .detail-container .description {
    font-size: 16px;
    line-height: normal;
  }
  .contact-info h3 {
    font-size: 18px;
    line-height: normal;
  }

  .contact-info li strong {
    font-size: 16px !important;
  }

  .contact-method {
    font-size: 14px;
  }
  .contact-icon {
    width: 40px;
    height: 40px;
  }
  .contact-icon svg {
    width: 16px;
    height: 16px;
  }

  .member-arrow {
    width: 30px;
    height: 30px;
  }

  .member-arrow svg {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 630px) {
  .detail-container {
    flex-direction: column;

    height: fit-content;
  }
  .member-photo-detail,
  .member-info-detail {
    flex: 1;
  }
  .member-photo-detail {
    height: fit-content;
    padding: 0;
    border-radius: 24px;
  }
  .member-photo-detail img {
    height: 500px;
    border-radius: 24px;
  }

  .team-member-detail .swipe {
    display: flex;
    margin-top: 20px;
  }
}

@media (max-width: 530px) {
  .member-photo-detail img {
    height: 400px;
  }
  .team-member-detail .profile {
    margin: 0 auto;
  }
}
@media (max-width: 400px) {
  .member-photo-detail img {
    height: 320px;
  }
}
