.client-testimonial-card {
  margin: 20px 0;
}
.client-testimo-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  width: 340px;
  /* width: 100%; */
  height: 310px;
  padding: 40px 20px;

  border-radius: 24px;
  color: #fff;
  border: 1px solid #67fb94;
}
.lower-testimony {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.client-testimo-card p {
  font-family: "Titillium Web";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  text-transform: capitalize;
}
.client-testimo-divider {
  width: 100%;
  height: 1px;
  background: #171d26;
}

.client-testimo-divider {
  position: relative;
}
.testimo-logo {
  position: absolute;
  top: -20px;
  left: 30%;

  width: 35px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  padding: 4px;
}
.testimo-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.testimo-quote {
  position: absolute;
  top: -78px;
  right: 10px;
  width: 150px;
  height: 150px;
}
.testimo-quote svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testifier-position {
  color: #67fb94;
  text-align: center;

  font-size: 16px;
  font-weight: 400;
  line-height: 30px;

  margin-top: -30px;
}

.testifier {
  color: #fff;
  text-align: center;

  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}

@media (max-width: 1345px) {
  .client-testimo-card {
    width: 300px;
    height: 350px;
    padding: 20px 20px 40px 20px;
  }
  .testifier {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  }
  .client-testimo-card p {
    font-weight: 500;
    line-height: 26px;
  }
}
@media (max-width: 1200px) {
  .client-testimo-card {
    width: 230px;
  }
}
@media (max-width: 968px) {
  .client-testimo-card {
    width: 320px;
    padding: 20px 20px 40px 20px;
  }
  /* .testifier {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  } */
  .client-testimo-card p {
    font-weight: 500;
    line-height: 26px;
  }
}
@media (max-width: 850px) {
  .client-testimo-card {
    width: 280px;
    padding: 20px 20px 40px 20px;
  }
  /* .testifier {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
  } */
  .client-testimo-card p {
    font-weight: 500;
    line-height: 26px;
  }
}
@media (max-width: 780px) {
  .client-testimo-card {
    width: 250px;
    padding: 20px 20px 40px 20px;
    justify-content: space-between;
  }
  /* .testifier {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
  } */
  .client-testimo-card p {
    font-weight: 500;
    line-height: 20px;
  }
}
@media (max-width: 680px) {
  .client-testimo-card {
    width: 190px;
    height: 350px;
    padding: 20px 20px 40px 20px;
  }
  .testifier {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
  .client-testimo-card p {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
  }
  .testimo-quote {
    width: 135px;
    height: 135px;
  }
}

@media (max-width: 550px) {
  .client-testimo-card {
    width: 300px;
    padding: 20px 20px 40px 20px;
  }
  /* .testifier {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  } */

  .client-testimo-card p {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
  }

  .client-testimo-card .testifier {
    font-size: 16px;
  }

  .testimo-quote {
    width: 100px;
  }
}
