.ex-vectors {
  display: flex;
  flex-direction: column;
  gap: 20px;
  fill-opacity: 0.2;
}
.vector-element1,
.vector-element2 {
  display: flex;
  gap: 60px;
}

.ex-vectors svg {
  fill: rgba(255, 255, 255, 0.2);
  opacity: 0.5;
}

.vector-element1 svg:nth-child(6) {
  visibility: hidden;
}
.vector-element2 svg:nth-child(1) {
  visibility: hidden;
}
.vector-element1 svg:nth-child(2) {
  stroke: #66fa93;
  stroke-width: 0.8px;
}
.vector-element2 svg:nth-last-child(2) {
  stroke: #66fa93;
  stroke-width: 0.8px;
}

.ex-vectors svg:hover {
  stroke: rgb(96, 159, 237);
  stroke-width: 0.8px;
}

@media (max-width: 1000px) {
  .vector-element1,
  .vector-element2 {
    gap: 40px;
  }
  .ex-vectors {
    gap: 20px;
  }
  .ex-vectors svg {
    width: 50px;
  }
}
@media (max-width: 900px) {
  .vector-element1,
  .vector-element2 {
    gap: 30px;
  }
  .ex-vectors {
    gap: 10px;
  }
  .ex-vectors svg {
    width: 30px;
  }
}
@media (max-width: 768px) {
  .ex-vectors {
  }
  .ex-vectors svg {
    width: 20px;
  }
}
