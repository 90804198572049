/* .test-container {
  width: 80%;
  height: 500px;
  margin: 0 auto;

  display: flex;
  gap: 10px;
} */
.test-card,
.slide-item {
  width: 100%;
  max-width: 320px;
  height: 320px;

  background: gray;

  display: flex;
  flex-direction: column;
  padding: 20px;

  border: 1px solid wheat;
  border-radius: 20px;
}

.slider-container {
  width: 80%;
  margin: 0 auto;
  color: #fff;
}

/* 
.slider-container {
  color: #fff;
  width: 80%;
  margin: 0 auto;
}

.slide-item {
  width: 100%;
  max-width: 320px;
  height: 320px;

  background: gray;

  display: flex;
  flex-direction: column;
  padding: 20px;

  border: 1px solid wheat;
  border-radius: 20px;
} */
