.choose-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center !important;
  place-items: start;
}
.choose-cards .home-heading {
  /* padding: 100px 0; */
}
.choose-card {
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
  padding: 24px;

  border-radius: 24px;
  border: 1px solid #67fb94;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
  color: #fff;
}
.last-card {
  width: 80%;
  margin: 0 auto;
  text-align: start;
}
.last-card .home-heading {
  text-align: start;
  margin: 20px 0 50px 0;
  font-size: 30px;
  width: 70%;
}
.choose-card-title {
  color: #67fb94;
}
