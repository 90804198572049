.blog-page {
  display: flex;
  flex-direction: column;
  gap: 200px;

  background: var(--Black, #0c0f14);
}

.blog-tags-mobile {
  padding-top: 20px;
  display: none;

  justify-content: center;
  gap: 10px;
}
.blog-tags-mobile .mobile-tag {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 26px;
  padding: 10px 15px;
  color: #fff;

  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  border-radius: 6px;
  border: 0.5px solid #c0c0c2;
}

.selected {
  border: 0.5px solid #67fb94 !important;
}
.blog-container {
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1.4fr 1fr;
  column-gap: 40px;
  justify-content: space-between;
  background: #0c0f14;
}

.blog-right {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 100px;
}
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 50%;
}
.posts-error {
  font-weight: 900;
  color: #fff;
  height: 100vh;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
}
.pagination {
  display: flex;
  margin: 50px auto;

  width: 50px;
  justify-content: center;
  gap: 10px;

  transform: translateY(-20px);
}

.pagination .page-number {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 15px;
  color: #fff;

  font-style: normal;
  font-weight: 600;
  line-height: 25px;

  border-radius: 6px;
  border: 0.5px solid #c0c0c2;
}
.page-number:hover {
  cursor: pointer;
}
@media (max-width: 1200px) {
  .category-container {
    padding: 20px !important;
  }

  .search-container {
    height: 160px !important;
    gap: 16px;
  }
  .search-container .search-title,
  .category-container .blog-card-title,
  .popular-posts-container .blog-card-title {
    font-size: 20px;
  }
  .search-box button svg {
    width: 24px !important;
    height: 24px !important;
  }
  .tags-blog-highlighter {
    top: 20px !important;
  }

  .search-container .blog-highlighter {
    top: 44px !important;
  }
  .category-highlighter {
    top: 28px !important;
  }
  .popular-blogs-highlighter {
    top: 40px !important;
  }
  .blog-container {
    grid-template-columns: 1.6fr 1fr;
  }

  .popular-posts-container .content a {
    font-size: 14px;
  }
  .popular-posts-container p {
    padding: 0;
    margin: 0;
  }

  .popular-posts-container .thumbnail {
    height: 70px;
  }
  .popular-posts-container {
    height: 300px !important;
  }
}
@media (max-width: 1000px) {
  .blog-container {
    display: flex !important;
    flex-direction: column;
  }
  .search-container,
  .category-container,
  .popular-posts-container,
  .tags-container {
    display: none !important;
  }
  .article-container .date-comment {
    flex-direction: row;
    gap: 20px;
  }

  .blog-container .blog-heading {
    font-size: 24px !important;
    line-height: 26px !important;
  }
}

@media (max-width: 820px) {
  .blog-container {
    margin-top: -200px;
  }
}

@media (max-width: 768px) {
  .blog-tags-mobile {
    display: flex;
  }
  .pagination {
    display: flex;
  }

  .blog-container .blog-desc {
    color: #c0c0c2;
  }
}
@media (max-width: 550px) {
  .posts-error {
    margin-top: 10px;
  }
}
