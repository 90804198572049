.service-card {
  width: 100%;
  /* max-width: 260px; */
  height: 120px;
  color: #fff;
  background-color: #161d27;
  box-shadow: 5px -5px 10px 0px rgba(39, 246, 234, 0.1);
  border-radius: 24px;
  padding: 32px 32px 40px 32px;
}

.service-card:hover {
  border: 1px solid #67fa94;
}
.card-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.service-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.service-card .subheading {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}
.card-text {
  width: 90%;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 750px) {
  .service-card {
    max-width: 210px;
    height: 100px;
  }

  .service-card .service-icons svg {
    width: 44px;
    height: 44px;
  }
}
@media (max-width: 650px) {
  .service-card {
    max-width: 170px;
    height: 100px;
  }

  .service-card .service-icons svg {
    width: 40px;
    height: 40px;
  }
  .service-card .subheading {
    font-size: 16px !important;
    line-height: normal;
    font-weight: 900;
  }
}
@media (max-width: 550px) {
  .service-card {
    max-width: 110px;
    height: 100px;
  }

  .service-card .service-icons svg {
    width: 30px;
    height: 30px;
  }
  .service-card .subheading {
    font-size: 14px !important;
    line-height: normal;
    font-weight: 900;
  }
}
@media (max-width: 420px) {
  .service-card {
    max-width: 80px;
    height: 80px;
  }

  .service-card .service-icons svg {
    width: 30px;
    height: 30px;
  }
  .service-card .subheading {
    font-size: 10px !important;
    line-height: normal;
    font-weight: 700;
  }
}
