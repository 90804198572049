.clients-page {
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 300px;
  background: #0c0f14;
  /* padding-bottom: 200px; */
}

.clients-container {
  width: 100%;
  max-width: 1450px;
  height: fit-content;
  margin: -50px auto 0 auto;
}
.clients-page .clients-subheading,
.clients-page .clients-heading {
  text-align: center;
  display: none;
  padding: 10px;
  transform: translateY(34px);
}

.project-overview {
  text-align: start !important;
  display: none;
}

.project {
  width: 80%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-summary {
  width: 100%;
  height: 570px;

  display: flex;
  gap: 10px;
}

.project-picture {
  width: 100%;
  height: 100%;
  background-color: lightgray;
  opacity: 0.5;

  flex: 2;
  border-radius: 160px 0px 0px 0px;
}

.project-picture img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 160px 0px 0px 0px;
}

.project-summary-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;

  padding-left: 60px;
  padding-right: 40px;
  width: 100%;
  height: 100%;

  color: #fff;
  border-radius: 0px 24px 0px 0px;
  border: 1px solid #67fb94;
  background: #161d27;
}

.project-socials {
  display: flex;
  gap: 10px;
  align-items: center;
}
.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ffffff16;
}
.social-link:hover {
  background: #66fa93;
}
.social-link svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}
.social-link svg:hover {
  fill: #000 !important;
}

.project-card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-details {
  display: grid;
  grid-template-columns: 1.6fr 1fr;
  gap: 20px;

  background: #161d27;
  color: #fff;
}
.project-details p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.project-desc {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.summary-challenge,
.project-results,
.solution {
  border: 1px solid #67fb94;
  padding: 16px;
}
.solution {
  border-radius: 0px 0px 24px 0px;
  border: 1px solid #67fb94;
  background: #161d27;
}
.pj-heading {
  color: #66fa93;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
}
.pj-title {
  color: #66fa93;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-transform: capitalize;
}

.pj-heading-desc {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  text-transform: capitalize;
}

/* --------------- CLIENTS LOGOS ------------------- */
.clients-page .carousel-container {
  display: none;
  margin-top: 50px;
}
/* --------------- SIMILAR PROJECTS ------------------- */

.similar-projects {
  width: 100%;
  height: 500px;
  text-align: center;
  margin: 100px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.more-projects {
  display: flex;
  gap: 20px;
  margin: 0 auto;
}

.clients-page .project-card img {
  object-fit: cover;
}
.no-casestudy {
  color: #fff;
  font-weight: 900;
  font-size: 16px;
}
.similar-projects .button-container {
  display: none;
}
@media (max-width: 1200px) {
  .project-summary-info {
  }
  .pj-heading-desc {
    font-size: 26px;
    line-height: 25px;
  }
}
@media (max-width: 1080px) {
  .project-picture {
    height: 90%;
  }

  .project-summary-info {
    height: 90%;
  }
  .pj-heading-desc {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (max-width: 968px) {
  .similar-projects {
    margin: 100px auto;
  }
  .more-projects {
    /* flex-wrap: wrap; */
  }
  .project-card {
    width: 290px !important;
    height: 250px !important;
  }

  .project-picture {
    height: 80%;
  }

  .project-summary-info {
    height: 80%;
  }
  .pj-heading-desc {
    font-size: 16px;
    line-height: normal;
  }

  .project-details {
    grid-template-columns: 1fr;
  }
  .project-overview {
    margin-top: -40px;
  }

  .project {
    gap: 5px;
  }

  .clients-page {
    gap: 200px;
  }
}

@media (max-width: 750px) {
  .project-card {
    height: 150px !important;
  }
  .project-summary {
    align-items: center;
    margin-top: -30px;
  }
  .clients-page .home-title,
  .clients-page .home-heading,
  .project-overview {
    display: block;
  }
  .clients-page .home-heading {
    color: rgba(255, 255, 255, 0.4);

    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    text-transform: capitalize;
  }

  .more-projects::-webkit-scrollbar {
    display: none;
  }

  .project-card.active {
    opacity: 1;
    display: flex;
    position: relative;
    height: 100%;
  }

  .more-projects .project-overlay {
    bottom: -22px;
    border-radius: 24px;
    background: var(--Brand-green, #7ac14b);

    width: 60%;
    z-index: 1;
    font-size: 9px;
    height: 20px;
    left: 24px;
  }

  .project-picture {
    height: 70%;
  }

  .project-summary-info {
    height: 65%;
    padding: 10px 20px 20px 20px;
  }

  .pj-heading {
    font-size: 14px;
  }
  .pj-heading-desc {
    font-size: 14px;
    line-height: normal;
  }
  .project-desc p {
    line-height: normal;
  }

  .project-details {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
  .project-overview {
    margin-top: -60px;
  }

  .clients-page {
    gap: 100px;
  }

  .clients-page .home-title {
    margin-bottom: -20px;
  }
  .clients-page .carousel-container {
    display: block;
  }
  .similar-projects .home-title {
    margin-bottom: -24px;
  }
  .clients-container {
    margin: -80px auto 0 auto;
  }
}

@media (max-width: 450px) {
  .similar-projects-container {
    display: grid;
    grid-template-columns: 5% 90% 5%;
    height: fit-content;
    width: 100%;
    place-items: center;
    margin: auto !important;
    padding: 0;
  }
  .similar-projects {
    width: 100% !important;
    height: fit-content;
    margin: 50px auto;
  }
  .more-projects {
    width: 80%;
    height: fit-content;

    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    scrollbar-width: none;
  }
  .more-projects .project-overlay {
    height: 20px;
    left: 40px;
  }
  .project-card {
    width: 90% !important;
    transition: transform 0.5s ease-in-out;
    opacity: 0;
    display: none;
    height: 100%;

    border-radius: 24px;
    border: 1px solid #67fb94;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.6);
  }

  .similar-projects .button-container {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .service-icon-left,
  .service-icon-right {
    display: flex;
  }
  .project-summary {
    height: 550px;
    margin-top: -70px;
  }
  .project-picture {
    flex: 1.8;
    height: 100%;
  }
  .project-summary-info {
    height: 55%;
    flex: 1;
    gap: 5px;
  }
  .project-summary-info h3 {
    padding: 0;
    margin: 0;
  }
  .project-client {
    gap: 4px !important;
  }
  .project-socials .social-link svg {
    width: 16px;
    height: 16px;
  }
  .pj-heading {
    font-weight: 500;
  }
  .pj-heading-desc {
    font-weight: 500;
  }

  .clients-page .slider-btn {
    width: 40px;
    height: 40px;
    display: flex;
  }
}
