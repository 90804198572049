.blog-card {
  width: 100%;
  max-width: 410px;
  height: 530px;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-decoration: none;
}

.card-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.blog-picture {
  width: 100%;
  min-height: 420px;
}
.blog-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #67fb94;
}

.radius-left img {
  border-radius: 40px 0px 0px 0px;
}
.radius-right img {
  border-radius: 0px 40px 0px 0px;
}
.blog-card-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-info {
  width: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-self: flex-start;
  gap: 10px;
}
.blog-card .blog-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  padding-left: 0;
}

.date-comment {
  display: flex;
  gap: 30px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
}
.blog-comment,
.blog-date {
  display: flex;
  gap: 8px;
  align-items: center;
}
.blog-arrow {
  fill: #67fb94;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #ffffff1e;
  border-radius: 50%;
}

.blog-arrow:hover {
  background: #67fb94;
}
.blog-arrow:hover svg {
  fill: #000;
}

@media (max-width: 1364px) {
  .blog-card {
    max-width: 300px;
    height: 480px;
  }

  .blog-picture {
    /* height: 300px; */
  }

  .date-comment {
    gap: 20px;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 1080px) {
  .blog-card {
    max-width: 255px;
    height: 440px;
  }

  .blog-picture {
    min-height: 340px;
  }

  .blog-card .blog-title {
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
  }

  .date-comment {
    gap: 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
  }
  .blog-arrow,
  .blog-arrow-black {
    width: 50px;
    height: 50px;
  }

  .blog-comment,
  .blog-date {
    gap: 4px;
    align-items: center;
  }
}
@media (max-width: 900px) {
  .blog-card {
    max-width: 30%;
    height: 390px;
  }

  .blog-picture {
    min-height: 300px;
  }

  .blog-card .blog-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .date-comment {
    flex-direction: column;
    gap: 10px;
    font-size: 12px;
    font-weight: 300;
  }
  .blog-arrow,
  .blog-arrow-black {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  .blog-comment,
  .blog-date {
    gap: 8px;
    align-items: center;
  }

  .blog-card-details {
  }
  .blog-info {
    /* gap: 10px; */
  }
}

@media (max-width: 750px) {
  .blog-picture {
    min-height: 250px;
  }
  .blog-card {
    height: 350px;
  }

  .blog-card .blog-title {
    font-size: 12px !important;
  }

  .blog-arrow,
  .blog-arrow-black {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 550px) {
  .blog-card {
    height: 240px;
  }
  .blog-picture {
    min-height: 200px;
  }

  .blog-card .blog-title {
    font-size: 10px !important;
    font-weight: 500 !important;
  }

  .blog-arrow,
  .blog-arrow-black {
    width: 20px;
    height: 20px;
  }

  .date-comment {
    font-size: 10px;
    gap: 6px;
  }
  .blog-arrow svg,
  .blog-arrow-black svg {
    width: 12px;
    height: 9px;
  }
}

@media (max-width: 470px) {
  .blog-picture {
    min-height: 100px;
  }
  .blog-card {
    width: 26%;
    height: 250px;
  }
  .card-wrap {
    gap: 10px;
  }

  .blog-card .blog-title {
    font-size: 8px !important;
    font-weight: 600 !important;
  }
  .blog-arrow {
    display: none;
  }

  .blog-arrow,
  .blog-arrow-black {
    width: 16px;
    height: 16px;
  }

  .date-comment {
    font-size: 5%;
    font-weight: 200;
    gap: 4px;
  }

  .blog-comment,
  .blog-date {
    gap: 4px;
  }
  .blog-arrow svg,
  .blog-arrow-black svg {
    width: 10px;
    height: 8px;
  }
}
