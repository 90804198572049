.slider-controls-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #fff;
}
.logo-container {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.logo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 60px;
}

.personal-info {
}
.full-name {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
}

.person-position {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

.slide-btn {
  display: flex;
  gap: 1em;
}
