.contact-page {
  position: relative;
  width: 100%;

  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 100px;
  background: #0c0f14;

  margin: 0 auto;
}

.contact-container {
  width: 100%;
  height: fit-content;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.contact-cards {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

/*------------------------  CONTACT FORM --------------------- */
.contact-form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  padding-bottom: 80px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 65%;
}
.contact-form .home-title {
  margin-left: -20px;
}
.contact-form h3 {
  color: #c0c0c2;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.form-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* ----------------------- VECTORS ---------------------- */

.contact-form-container svg {
  fill-opacity: 0.2;
}
.contact-form-vectors {
  position: absolute;
  top: 0;
  right: 0;
  display: grid;
  row-gap: 60px;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
  width: 153px;
}

.contact-form-vectors svg {
  transform: rotate(90deg);
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.2);
}
.contact-form-vectors svg:hover {
  stroke: rgb(96, 159, 237);
  stroke-width: 0.8px;
}
.contact-form-vectors svg:nth-child(1) {
  visibility: hidden;
}

.contact-form-vectors svg:nth-child(4),
.contact-form-vectors svg:nth-child(11) {
  fill: rgba(255, 255, 255, 0.2);
  stroke: #66fa93;
  stroke-width: 0.8px;
}
.contact-form-vectors svg:nth-child(12) {
  visibility: hidden;
}

/* ----------------------- VECTORS ---------------------- */

.name-email {
  display: grid;
  grid-template-columns: repeat(2, 45%);
  justify-content: space-between;
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 220px;
  height: 70px;
  border-radius: 24px;
  background: #67fb94;

  color: #0c0f14;
  text-align: right;
  font-family: "Sora";
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;

  cursor: pointer;
}
.submit-btn:hover {
  opacity: 0.5;
}

/* ----------------------- MAPS ---------------------- */
.contact-page iframe {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(75%);
  opacity: 0.4;
}

@media (max-width: 1080px) {
  .contact-form-vectors {
    width: 100px;
    row-gap: 50px;
    right: 40px;
  }
}
@media (max-width: 968px) {
  .contact-form-vectors {
    display: none;
  }
  .contact-page .contact-form {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contact-form {
    gap: 30px;
  }
  .contact-form .home-heading {
    color: rgba(255, 255, 255, 0.4);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
  }
  .contact-form h3 {
    display: none;
  }
}

@media (max-width: 650px) {
  .contact-page .contact-form .name-email {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contact-page .contact-form .name-email .input-wrap input {
    width: 100%;
    height: 40px;
  }
  .contact-page .contact-form textarea {
    height: 100px;
  }
  .submit-btn {
    width: 150px;
    height: 56px;
    line-height: normal;
    font-size: 14px;
  }
  .submit-btn svg {
    width: 14px;
  }
}
@media (max-width: 550px) {
  .contact-page .contact-cards {
    flex-wrap: wrap;
  }
  .contact-page .contact-card {
    width: 40%;
  }
}
