.testimonial {
  display: grid;

  place-items: center;
  background: #171d26;
  /* width: 100%; */
}

.testimonial-primary {
  grid-template-columns: 40% 6% 10%;
}
.testimonial-secondary {
  grid-template-columns: 10% 6% 40%;
  justify-content: end;
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid #67fb94;
}
.talent-testimonials .quote {
  left: 50%;
}

.testimonial-content p {
  color: #fff;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  padding: 20px 20px 0 20px;
}
.testimonial-author p {
  padding: 0;
}
.testimonial-author {
  text-align: start;
  padding: 0 20px 10px 20px;
  text-align: right;
  font-size: 14px;
}
.testimonial-author p {
  padding: 0;
  margin: 0;
}

.testimonial-author p:nth-child(2) {
  color: #67fb94;
}
.testimonial-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #67fb94;
}

.translate-primary {
  transform: translateX(-16px);
}
.translate-secondary {
  transform: translateX(16px);
}

.testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  /* border: 2px solid #67fb94; */
}

/*====================== VECTORS  =======================  */

.left-vectors {
  position: absolute;
  overflow: hidden;
  left: 0;
}

.right-vectors {
  position: absolute;
  overflow: hidden;
  right: 0;
}

.left-vectors svg,
.right-vectors svg {
  width: 100%;
  stroke-width: 0.5px;
  opacity: 0.2;
}
/*====================== VECTORS  =======================  */

/*====================== MEDIA QUIRIES =======================  */

@media (max-width: 1200px) {
  .testimonial-primary {
    grid-template-columns: 55% 6% 10%;
    transform: translateX(2px);
  }

  .testimonial-secondary {
    grid-template-columns: 10% 6% 55%;
    transform: translateX(-30px);
  }

  .testimonial-content p {
    font-size: 14px;
  }
}
@media (max-width: 1080px) {
  .testimonial-primary {
    grid-template-columns: 60% 6% 10%;
    transform: translateX(10px);
  }

  .testimonial-secondary {
    grid-template-columns: 10% 6% 60%;
    transform: translateX(-20px);
  }

  .testimonial-content p {
    line-height: 18px;
  }
}
@media (max-width: 950px) {
  .testimonial-primary {
    grid-template-columns: 70% 10% 10%;
  }

  .testimonial-secondary {
    grid-template-columns: 10% 10% 70%;
  }

  .testimonial-content p {
    font-weight: 400;
  }
}
@media (max-width: 800px) {
  .testimonial-image {
    width: 80px;
    height: 80px;
  }
  .testimonial-primary {
  }

  .testimonial-secondary {
  }

  .testimonial-content p {
    padding: 4px 10px 0 10px;
  }
  .testimonial-author .name {
    line-height: 20px;
  }
}
@media (max-width: 650px) {
  .testimonial-secondary {
    display: none;
  }

  .divider-container {
    display: none;
  }
  .testimonial-content {
    padding: 10px;
    border-radius: 60px;
    border: 2px solid #67fb94;
  }

  .testimonial-image {
    width: 80px;
    height: 80px;
  }
  .testimonial-content p {
    line-height: normal;
    font-weight: normal;
  }
  .testimonial-content .testimonial-author p:nth-child(2) {
    display: none;
  }
  .testimonial-content .testimonial-author p:nth-child(1) {
    color: #67fb94;
  }

  .testimonial-primary {
    grid-template-columns: 80% 15% 5%;
  }

  .testimonial-secondary {
    grid-template-columns: 5% 15% 80%;
  }
}

@media (max-width: 580px) {
  .testimonial-content p {
    font-size: 12px;
    padding: 4px;
  }
  .testimonial-primary {
    grid-template-columns: 80% 15% 5%;
  }
  .testimonial-secondary {
    grid-template-columns: 5% 15% 80%;
  }
  .testimonial-image {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 500px) {
  .testimonial-primary {
  }

  .testimonial-content {
    /* padding: 8px 4px; */
    border: 1px solid #67fb94;
  }
  .testimonial-content p {
    font-size: 10px;
    font-weight: 300;
  }

  .testimonial-primary {
    grid-template-columns: 85% 15% 5%;
    transform: translateX(-10px);
  }

  .testimonial-secondary {
    grid-template-columns: 5% 15% 85%;
    transform: translateX(10px);
  }
}
