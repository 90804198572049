.service-home {
  width: 100%;
  height: 900px;

  padding: 40px 0;
  margin-bottom: 100px;
}

.service-home-wrap {
  position: relative;
}

.service-home-content {
  width: 90%;
  max-width: 1400px;

  display: flex;
  flex-direction: column;

  margin: 0 auto;
  padding-top: 100px;
}

.service-home .home-title {
}
.service-home .home-heading {
  margin-bottom: 40px;
}
.our-service-statement {
  color: #c0c0c2;
  margin-bottom: 40px;
}

.success-story {
  font-weight: bold;
}
.cards-wrapper {
  position: relative;

  display: flex;
  justify-content: center;
}

.service-home-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  gap: 20px;
}

/*-------------- MEDIA QUIRIES ---------------------------------------------- */
@media (min-width: 1100px) {
}
@media (min-width: 1024px) {
  .service-home-cards {
    margin: 0 auto;
    justify-content: start;
  }
  .service-card {
    flex: 1;
  }

  .service-icons svg {
    width: 50px;
  }
  .card-text {
    width: 100%;
  }

  .service-card .subheading {
    font-size: 20px;
  }
  .service-card .card-text {
    line-height: 20px;
  }

  .service-home {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .service-home-cards {
    margin: 0 auto;
    justify-content: center;
  }
  .service-card {
    width: 320px;
  }
  .slick-list {
    margin: 0 auto !important;
    transform: translateX(50px) !important;
  }

  .service-home .left-btn {
    width: 50px;
    height: 50px;
    left: -10px;
  }
  .service-home .right-btn {
    width: 50px;
    height: 50px;
    right: -60px;
  }

  .service-icons svg {
    width: 50px;
  }

  .card-text {
    font-size: 16px;
    line-height: normal;
  }
}

@media (max-width: 940px) {
  .service-home-cards {
    margin: 0 auto;
    justify-content: center;
  }
  .service-card {
    width: 280px;
  }

  .slick-list {
    margin: 0 auto !important;
    transform: translateX(20px) !important;
  }

  .service-home .left-btn {
    width: 50px;
    height: 50px;
    left: -38px;
  }
  .service-home .right-btn {
    width: 50px;
    height: 50px;
    right: -50px;
  }

  .service-icons svg {
    width: 50px;
  }

  .service-card .subheading {
    font-size: 20px;
  }
}

@media (max-width: 850px) {
  .service-home .service-card {
    width: 220px;
  }
  .service-home .left-btn {
    width: 40px;
    height: 40px;
    left: -38px;
  }

  /* .slick-list {
    transform: translateX(6px) !important;
  } */
  .service-home .right-btn {
    width: 40px;
    height: 40px;
    right: -40px;
  }
  .card-contents {
    gap: 10px;
  }

  .card-text {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    line-height: 30px;
  }
}

@media (max-width: 700px) {
  .service-home .service-card {
    margin: 0 auto;
    width: 320px;
  }
  .slick-list {
    transform: translateX(-6px) !important;
  }
  .service-home-wrap {
    width: 100% !important;
  }

  .service-home .left-btn {
    width: 60px;
    height: 60px;
    left: -10px;
  }

  .service-home .right-btn {
    width: 60px;
    height: 60px;
    right: -10px;
  }
}

@media (max-width: 550px) {
  .service-home .service-card {
    margin: 0 auto;
    width: 60%;
  }
  .service-home .left-btn {
    width: 40px;
    height: 40px;
    left: -15px;
  }

  .service-home .right-btn {
    width: 40px;
    height: 40px;
    right: -16px;
  }

  .service-home .home-heading {
    margin-bottom: 10px;
  }

  .our-service-statement {
    font-size: 14px;
    line-height: normal;
  }
}
